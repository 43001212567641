import React, {useContext, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import CampaignFormContext from "../CampaignFormContext";
import QUANTITY_MODE from "../../../constants/QuantityMode";
import {FormattedMessage} from "react-intl";
import noImage from '../../../assets/no-image.png';

const PrizeRow = ({prize, onEdit, onDelete}) => {
    const isAdmin = useSelector(state => state.auth.userData.isAdmin);
    const {campaign} = useContext(CampaignFormContext);

    const imageUrl = useMemo(() => {
        if (prize.image instanceof File) {
            return URL.createObjectURL(prize.image);
        }

        return prize.image;
    }, [prize.file, prize.image]);

    return (
        <tr>
            <td className="vertical-align-middle">
                <img
                    src={imageUrl || noImage}
                    alt={prize.name}
                    style={{maxHeight: '80px'}}
                />
            </td>
            <td className="vertical-align-middle">
                {prize.name}
            </td>
            <td className="vertical-align-middle">
                {prize.quantityMode === QUANTITY_MODE.prorata ? prize.quantity : (
                    <FormattedMessage id="PrizeRow.dailyQuantity"/>
                )}
            </td>
            <td className="vertical-align-middle">
                {prize.probability}
            </td>
            <td className="vertical-align-middle">
                <div className="buttons">
                    <button className="button is-info" onClick={() => onEdit(prize)}>
                        <i className="mdi mdi-pencil"/>
                    </button>
                    {(!campaign.isStarted || isAdmin) && (
                        <button className="button is-danger" onClick={() => onDelete(prize)}>
                            <i className="mdi mdi-delete"/>
                        </button>
                    )}
                </div>
            </td>
        </tr>
    );
};

PrizeRow.propTypes = {
    prize: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default PrizeRow;
