import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';
import {useTranslate} from 'hooks';
import Table from 'components/Table';
import {fetchUsers} from 'state/actions/users';
import {
    fetchCampaigns,
    deleteCampaign,
    CAMPAIGNS_FETCH_DATA_SUCCESS,
    duplicateCampaign
} from 'state/actions/campaigns';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import firebase from "firebase";
import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import firebaseDateToDate from '../../utils/firebaseDateToDate';
import classes from './index.module.scss';
import {CAMPAIGN_STEPS} from "../../constants/CampaignSteps";

const CampaignList = () => {
    const {t, formatDate} = useTranslate();
    const dispatch = useDispatch();
    const {users, isAdmin, loading, deleted, campaigns, userId, allowedCampaigns, duplicated} = useSelector(state => ({
        users: state.users.data,
        isAdmin: state.auth.userData.isAdmin,
        userId: state.auth.userData.id,
        allowedCampaigns: state.auth.userData.allowedCampaigns,
        loading: state.campaigns.loading || state.users.loading,
        deleted: state.campaigns.deleted,
        duplicated: state.campaigns.duplicated,
        campaigns: state.campaigns.data,
    }));

    const [search, setSearch] = useState('');
    const [deleting, setDeleting] = useState(null);
    const [startCampaign, setStartCampaign] = useState(null);
    const [starting, setStarting] = useState(null);
    const [duplicating, setDuplicating] = useState(null);
    const [data, setData] = useState([]);

    const usernameById = (id) => users?.find(user => user.id === id)?.name || '';

    useEffect(() => {
        if (isAdmin) {
            dispatch(fetchUsers());
        }

        dispatch(fetchCampaigns());
    }, [dispatch, isAdmin]);

    useEffect(() => {
        if (deleted && !loading) {
            setDeleting(null);
        }
    }, [deleted, loading]);

    useEffect(( )=> {
        if (duplicated && !loading) {
            setDuplicating(null);
        }
    }, [duplicated, loading]);

    useEffect(() => {
        const campaignsList = search ? campaigns.filter(campaign => {
            return [
                campaign.name,
                usernameById(campaign.ownerId)
            ].some(field => field.toLowerCase().includes(search.toLowerCase()));
        }) : [...campaigns.filter(i=> i.createdAt).sort(function(x, y){
            return x?.createdAt?.seconds - y?.createdAt?.seconds;
        }), ...campaigns.filter(i=> !i.createdAt)];

        setData(campaignsList);
    }, [campaigns, search]);

    const handleDelete = (campaignId) => setDeleting(campaignId);
    const handleCloseConfirm = () => setDeleting(null);
    const handleDeleteConfirm = () => dispatch(deleteCampaign(deleting));
    const handleDuplicate = (campaignId) => setDuplicating(campaignId);
    const handleDuplicateConfirm= () => dispatch(duplicateCampaign(duplicating));
    const handleCloseDuplicate= () => setDuplicating(null);

    const handleStart = (campaignId) => setStartCampaign(campaignId);
    const handleCloseStart = () => setStartCampaign(null);
    const handleStartConfirm = async () => {
        setStarting(true);
        await firebase.firestore().collection('campaigns').doc(startCampaign).update({isStarted: true});

        let query = firebase.firestore().collection('campaigns');
        if (!isAdmin) {
            query = query.where("ownerId", "==", userId);
        }
        const snap = await query.get();
        dispatch(CAMPAIGNS_FETCH_DATA_SUCCESS({campaigns: snap.docs.map((doc) => ({id: doc.id, ...doc.data()}))}));

        setStartCampaign(null);
        setStarting(false);
    };

    const handleSearch =(e)=> {
        setSearch(e.target.value);
    };

    const handleQrImage = (id)=> {
        const qrCodeCanvas= document.getElementById(id);
        const qrCodeDataUri = qrCodeCanvas.toDataURL("image/png");

        const pdf = new jsPDF('p','mm',[297, 210]);
        pdf.addImage(qrCodeDataUri, "JPEG", 45, 50, 125, 125);
        pdf.save("download.pdf");
    };

    const columns = [
        isAdmin ? {
            Header: t('CampaignList.user'),
            accessor: campaign => usernameById(campaign.ownerId)
        } : null,
        {
            Header: t('CampaignList.name'),
            accessor: 'name'
        },
        {
            Header: t('CampaignList.startDate'),
            accessor: 'startDate',
            Cell: ({row}) => (
                <small className="has-text-grey is-abbr-like">
                    {formatDate(firebaseDateToDate(row.original.startDate))}
                </small>
            )
        },
        {
            Header: t('CampaignList.endDate'),
            accessor: 'endDate',
            Cell: ({row}) => (
                <small className="has-text-grey is-abbr-like">
                    {formatDate(firebaseDateToDate(row.original.endDate))}
                </small>
            )
        },
        {
            Header: t('CampaignList.isStarted'),
            accessor: 'isStarted',
            Cell: ({row}) => row.original.isStarted ? (
                <span className="tag is-success">Oui</span>
            ) : (
                <span className="tag is-danger">Non</span>
            )
        },
        {
            Header: '',
            id: 'actions',
            accessor: 'actions',
            Cell: ({row}) => (
                <div className="buttons is-right">
                    {row.original.isStarted && (
                        <button
                            type='button'
                            className='button is-small is-link'
                            onClick={() => handleQrImage(row.original.id)}
                        >
                            <div className='icon is-small tooltip'>
                                <i className='mdi mdi-qrcode-scan' />
                                <span className='tooltiptext'>{t('CampaignList.qrcodeScan')}</span>
                            </div>
                        </button>
                    )}
                    {row.original.isStarted && (
                        <>
                            <Link
                                to={paths.CAMPAIGN_SHOWCASE.replace(':id', row.original.id)}
                                className="button is-small is-link"
                                target="_blank"
                            >
                                <div className="icon is-small tooltip">
                                    <i className="mdi mdi-barcode"/>
                                    <span className="tooltiptext">{t('CampaignList.display')}</span>
                                </div>
                            </Link>
                            <Link
                                to={paths.CAMPAIGN_CONSULTATION.replace(':id', row.original.id)}
                                className="button is-small is-link"
                            >
                                <div className="icon is-small tooltip">
                                    <i className="mdi mdi-file-find"/>
                                    <span className="tooltiptext">{t('CampaignList.find')}</span>
                                </div>
                            </Link>
                        </>
                    )}
                    {!row.original.isStarted && row.original.isCompleted && (
                        <button
                            onClick={() => handleStart(row.original.id)}
                            type="button"
                            className="button is-small is-primary"
                        >
                            <div className="icon is-small tooltip">
                                <i className="mdi mdi-rocket"/>
                                <span className="tooltiptext">{t('CampaignList.startCampaign')}</span>
                            </div>
                        </button>
                    )}

                    <Link
                        to={paths.MODIFY_CAMPAIGN.replace(':id', row.original.id).replace(':tab?', CAMPAIGN_STEPS.general)}
                        className="button is-small is-warning"
                    >
                        <div className="icon is-small tooltip">
                            <i className="mdi mdi-account-edit"/>
                            <span className="tooltiptext">{t('CampaignList.edit')}</span>
                        </div>
                    </Link>

                    {row.original.isStarted || (
                        <button
                            type="button"
                            className="button is-small is-danger"
                            onClick={() => handleDelete(row.original.id)}
                        >
                            <div className="icon is-small tooltip">
                                <i className="mdi mdi-trash-can"/>
                                <span className="tooltiptext">{t('CampaignList.delete')}</span>
                            </div>
                        </button>
                    )}

                    {(isAdmin || campaigns.length < allowedCampaigns) &&
                    <button
                      type='button'
                      className='button is-small is-link is-light'
                      onClick={() => handleDuplicate(row.original.id)}
                    >
                        <div className='icon is-small tooltip'>
                            <i className='mdi mdi-content-duplicate' />
                            <span className='tooltiptext'>{t('CampaignList.duplicate')}</span>
                        </div>
                    </button>}

                    <div className='is-hidden'>
                        <QRCode
                            id={row.original.id}
                            value={`https://${window.location.hostname}/campagne/${row.original.id}`}
                            renderAs="png"
                            size={600}
                        />
                    </div>
                </div>
            ),
            disableSortBy: true
        }
    ].filter(v => v);

    return (
        <>
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <h1 className="title">{t('CampaignList.title')}</h1>
                            </div>
                        </div>
                        {(isAdmin || campaigns.length < allowedCampaigns) &&
                        <div className="level-right">
                            <div className="level-item">
                                <Link to={paths.ADD_CAMPAIGN} className="button">
                                    {t('CampaignList.new')}
                                </Link>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </section>
            <section className="section is-main-section">
                {loading ? (
                    <div className="text-center mt-5 mb-5">
                        <ClipLoader/>
                    </div>
                ) : (
                    <div className="card has-table has-mobile-sort-spaced">
                        <header className="card-header">
                            <p className={classNames('card-header-title', classes.tableHeader)}>
                                <span>{t('Users.search')}</span>
                                <input
                                    type="text"
                                    className="input"
                                    value={search}
                                    onChange={(e) => handleSearch(e)}
                                />
                            </p>
                        </header>
                        <div className="b-table">
                            <Table columns={columns} data={data}/>
                        </div>
                    </div>
                )}
            </section>

            <ConfirmationModal
                isActive={!!deleting}
                isLoading={loading}
                confirmButtonMessage={t('Users.delete')}
                title={t('Users.confirm')}
                body={t('Users.permDelete')}
                cancelButtonMessage={t('Users.cancel')}
                onConfirmation={handleDeleteConfirm}
                onCancel={handleCloseConfirm}
            />

            <ConfirmationModal
                isActive={!!startCampaign}
                isLoading={starting}
                confirmButtonMessage={t('CampaignList.start')}
                title={t('CampaignList.startConfirm')}
                body={t('CampaignList.startMessage')}
                confirmButtonClass="is-primary"
                cancelButtonMessage={t('Common.cancel')}
                onConfirmation={handleStartConfirm}
                onCancel={handleCloseStart}
            />

            <ConfirmationModal
              isActive={!!duplicating}
              isLoading={loading}
              confirmButtonMessage={t('Users.duplicate')}
              title={t('Users.confirm')}
              body={t('Users.permDuplicate')}
              confirmButtonClass="is-warning"
              cancelButtonMessage={t('Users.cancel')}
              onConfirmation={handleDuplicateConfirm}
              onCancel={handleCloseDuplicate}
            />
        </>
    );
};

export default CampaignList;
