import React, {useEffect} from 'react';
import {useChangeHandler, useTranslate} from 'hooks';
import Switch from "react-switch";
import useStateSync from "../../../hooks/useStateSync";
import PropTypes from 'prop-types';
import FIELD_TYPES from "../../../constants/FieldTypes";

const FieldForm = ({data, onSubmit}) => {
    const {t} = useTranslate();
    const [localData, setLocalData] = useStateSync(data);

    const {hasMaxLength, hasMin, hasMax, hasList, hasRequired, type} = FIELD_TYPES.find(({id}) => id === localData.id);

    /**
     * Automatically update type attribute when field id change
     */
    useEffect(() => {
        setLocalData({...localData, type});
    }, [localData.id, type]);

    const handleChange = useChangeHandler(setLocalData);

    const handleChangeRequired = (checked) => {
        setLocalData({
            ...localData,
            required: checked
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const cleanData = {...localData};

        if (!hasMaxLength) {
            delete cleanData.maxLength;
        }

        if (!hasMin) {
            delete cleanData.min;
        }

        if (!hasMax) {
            delete cleanData.max;
        }

        if (!hasList) {
            delete cleanData.list;
        }

        Object.keys(cleanData).forEach((key) => {
            if (!cleanData[key] || cleanData[key].toString().trim().length < 1) {
                delete cleanData[key];
            }
        });

        onSubmit(cleanData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="columns is-multiline is-vcentered">
                <div className="column is-half">
                    <div className="field">
                        <label className="label required">
                            {t('FieldForm.name')}
                        </label>
                        <div className="control">
                            <input
                                className="input"
                                type="text"
                                name="name"
                                required
                                value={localData.name}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="column is-half">
                    <div className="field">
                        <label className="label required">
                            {t('FieldForm.type')}
                        </label>
                        <div className="select w-100">
                            <select className="input" required name="id" value={localData.id} onChange={handleChange}>
                                {FIELD_TYPES.map(({id}) => (
                                    <option value={id}>{t(`FieldTypes.${id}`)}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                {hasMaxLength && (
                    <div className="column is-half">
                        <div className="field">
                            <label className="label">
                                {t('FieldForm.maxLength')}
                            </label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="number"
                                    name="maxLength"
                                    min={0}
                                    value={localData.maxLength}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {hasMin && (
                    <div className="column is-half">
                        <div className="field">
                            <label className="label">
                                {t('FieldForm.min')}
                            </label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="number"
                                    name="min"
                                    value={localData.min}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {hasMax && (
                    <div className="column is-half">
                        <div className="field">
                            <label className="label">
                                {t('FieldForm.max')}
                            </label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="number"
                                    name="max"
                                    value={localData.max}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {hasList && (
                    <div className="column is-half">
                        <div className="field">
                            <label className="label required">
                                {t('FieldForm.list')}
                            </label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="text"
                                    name="list"
                                    required
                                    value={localData.list}
                                    onChange={handleChange}
                                />
                                <span className="help">
                                    {t('FieldForm.helpList')}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                {hasRequired && (
                    <div className="column is-half">
                        <div className="field mt-5">
                            <label className="is-flex is-aligned-center">
                                <Switch onChange={handleChangeRequired} checked={localData.required}/>
                                <div className="ml-3">
                                    {t('FieldForm.required')}
                                </div>
                            </label>
                        </div>
                    </div>
                )}
            </div>
            <button type="submit" className="button is-primary">
                {t('FieldForm.add')}
            </button>
        </form>
    );
};

FieldForm.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FieldForm;
