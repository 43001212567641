import React from 'react';
import useHideSidebar from "../../hooks/useHideSidebar";
import useQueryParam from "../../hooks/useQueryParam";
import usePublicCampaign from "../../hooks/usePublicCampaign";
import {useTranslate} from "../../hooks";
import useCampaignTitle from "../../hooks/useCampaignTitle";
import MobileLayout from "../../components/MobileLayout";
import ClipLoader from "react-spinners/ClipLoader";

const Error = () => {
    useHideSidebar();
    const {t} = useTranslate();

    const id = useQueryParam('id');
    const error = useQueryParam('error');
    const campaign = usePublicCampaign(id);

    const errorTitle = campaign?.labels.errorTitle || t(`Errors.title`);
    const errorMessage = campaign?.labels[error] || campaign?.labels.errorDefault || t(`Errors.${error}`, {
        center: (chunks) => `<div class="text-center">${chunks}</div>`
    }, t('Errors.default'));

    useCampaignTitle(campaign, 'errorMetaTitle', t(`Errors.errorMetaTitle`));

    if (id && !campaign) {
        return (
            <div className="text-center my-6">
                <ClipLoader/>
            </div>
        );
    }

    return (
        <MobileLayout campaign={campaign} title={errorTitle}>
            <div dangerouslySetInnerHTML={{__html: errorMessage}}/>
        </MobileLayout>
    );
};

export default Error;