import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';

import { shallowEqual, useSelector } from 'react-redux';

import './DatePicker.scss';
import firebaseDateToDate from "../../utils/firebaseDateToDate";
import dateToFirebaseDate from "../../utils/dateToFirebaseDate";

registerLocale('en', en);
registerLocale('es', es);
registerLocale('fr', fr);

const dateFormat = locale => {
  switch (locale) {
    case 'en':
      return 'MM-dd-yy';
    case 'es':    
    case 'fr':
      return 'dd/MM/yy';
      
    default:
      return 'MM-dd-yy';
  }
};

const DatePickerStyled = ({ name, date, minDate, maxDate, onChange, ...props }) => {
  const { locale } = useSelector(
    state => ({
      locale: state.preferences.locale
    }),
    shallowEqual
  );

  return (
    <DatePicker
      locale={locale}
      dateFormat={dateFormat(locale)}
      selected={firebaseDateToDate(date)}
      maxDate={firebaseDateToDate(maxDate)}
      minDate={firebaseDateToDate(minDate)}
      onChange={onChange}
      {...props}
    />
  );
};

DatePickerStyled.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired
};

DatePickerStyled.defaultProps = {
  minDate: null,
  maxDate: null,
};

export default DatePickerStyled;
