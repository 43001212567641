import React, {useState, useEffect} from 'react';
import {ClipLoader} from "react-spinners";
import PropTypes from 'prop-types';

const ClientForm = ({fields, loading, submitLabel, rgpd, handleSubmit}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const items = fields.map((field) => {
            const value = 'select' === field.id ? field.list.split(/;/).shift() : null;
            return {id: field.id, name: field.name, value};
        });
        setData(items)
    }, [fields]);

    const handleChange = e => {
        const {value, name} = e.target;
        const newData = data.map(field => field.name === name ? ({...field, value}) : field);
        setData(newData);
    };

    const handleCheckbox = (e) => {
        const {checked, value, name} = e.target;

        let selected = [...data]?.find(({id}) => id === 'checkbox')?.value?.split(';') ?? [];
        if (checked) {
            selected.push(value);
        } else {
            selected = selected.filter(v => v !== value);
        }

        const newData = data.map(field => field.name === name ? ({...field, value: selected.join(';')}) : field);
        setData(newData);
    };

    const handleForm = (e) => {
        e.preventDefault();
        handleSubmit(data);
    };

    return (
        <form onSubmit={handleForm}>
            {fields.map(({id, list, ...field}, i) => {
                    const Label = <label className={`label ${field.required && 'required'}`}>{field.name}</label>;
                    switch (field.type) {
                        case 'select':
                            return (
                                <div key={i} className="field">
                                    {Label}
                                    <div className="select w-100">
                                        <select className="w-100" required={field.required} onChange={handleChange}
                                                name={field.name}>
                                            {list.split(/;/).map((value, i) => <option key={i}>{value}</option>)}
                                        </select>
                                    </div>
                                </div>
                            );
                        case 'radio':
                            return (
                                <div key={i} className="field">
                                    {Label}
                                    <div className="control">
                                        {list.split(/;/).map((value, i) => (
                                           <div className="mb-1">
                                               <label key={i} className="radio">
                                                   <input
                                                       type="radio"
                                                       name={field.name}
                                                       value={value}
                                                       required={field.required}
                                                       onChange={handleChange}
                                                       className="mr-1"
                                                   />
                                                   <span className={field.required && 'required'}>{value}</span>
                                               </label>
                                           </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        case 'checkbox':
                            return (
                                <div key={i} className="field mb-1">
                                    {Label}
                                    <div className="control">
                                        {list.split(/;/).map((value, i) => (
                                            <div className="mb-1">
                                                <label key={i} className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name={field.name}
                                                        value={value}
                                                        onChange={handleCheckbox}
                                                        className="mr-1"
                                                        required={field.required}
                                                    />
                                                    <span className={field.required && 'required'}>{value}</span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        default:
                            return (
                                <div key={i} className="field">
                                    {Label}
                                    <div className="control">
                                        <input className="input" {...field} onChange={handleChange}/>
                                    </div>
                                </div>
                            );
                    }
                }
            )}

            <div className="mt-5 mb-5">
                <label className="checkbox mr-1">
                    <input
                        type="checkbox"
                        name="rgpd"
                        className="mr-1"
                        required
                    />
                    <span className="has-text-weight-bold required rgpd" dangerouslySetInnerHTML={{__html: rgpd}}/>
                </label>
            </div>

            <button type="submit" className="button is-info is-medium w-100" disabled={loading}>
                {loading && (
                    <div className="is-inline-block mr-2">
                        <ClipLoader size={12} color="white"/>
                    </div>
                )} {submitLabel}
            </button>
        </form>
    );
};

ClientForm.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        required: PropTypes.bool,
        list: PropTypes.string,
    })).isRequired,
    rgpd: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    submitLabel: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default ClientForm;
