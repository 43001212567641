import React, {useContext, useMemo} from 'react';
import {useChangeHandler, useTranslate} from 'hooks';
import {format, eachDayOfInterval, isSameDay } from 'date-fns';
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import Modal from "../../../components/Modal";
import CampaignFormContext from "../CampaignFormContext";
import useStateSync from "../../../hooks/useStateSync";
import firebaseDateToDate from "../../../utils/firebaseDateToDate";

const DailyQuantitiesModal = ({dailyUsedQuantities, dailyQuantities, isOpen, onClose, onSave}) => {
    const {t} = useTranslate();
    const [localQuantities, setLocalQuantities] = useStateSync(dailyQuantities);
    const {campaign} = useContext(CampaignFormContext);
    const {formatDate} = useTranslate();

    const dates = useMemo(() => {
        if (isSameDay(firebaseDateToDate(campaign.startDate), firebaseDateToDate(campaign.endDate))) {
            return [firebaseDateToDate(campaign.startDate)];
        }

        return eachDayOfInterval({
            start: firebaseDateToDate(campaign.startDate),
            end: firebaseDateToDate(campaign.endDate),
        });
    }, [campaign.startDate, campaign.endDate]);

    const values = useMemo(() => {
        const values = {};
        dates.forEach((date) => {
            const key = format(date, 'yyyy-MM-dd');
            values[key] = localQuantities[key] ?? 0;
        });
        return values;
    }, [dates, localQuantities]);

    const handleChange = useChangeHandler(setLocalQuantities);

    const handleSave = () => onSave(values);

    return (
        <Modal isOpen={isOpen} onClose={onClose} title={t('DailyQuantities.title')}>
            <div className="modal-card-body">
                <div className="columns is-multiline">
                    {dates.map((date, i) => {
                        const key = format(date, 'yyyy-MM-dd');
                        return (
                            <div key={i} className="column is-one-third">
                               <div className="field">
                                   <label className="label required">
                                       {formatDate(date)}
                                   </label>
                                   <div className="control">
                                       <input
                                           className="input"
                                           type="number"
                                           required
                                           name={key}
                                           min={0}
                                           value={values[key]}
                                           onChange={handleChange}
                                       />
                                       {campaign.isStarted && (
                                           <p className="help">
                                               <FormattedMessage id="DailyQuantities.availableQuantity" values={{count: (values[key] ?? 0) - (dailyUsedQuantities[key] ?? 0)}}/>
                                           </p>
                                       )}
                                   </div>
                               </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <footer className="modal-card-foot">
                <button className="button is-success" onClick={handleSave}>
                    <FormattedMessage id="Common.save"/>
                </button>
                <button className="button" onClick={onClose}>
                    <FormattedMessage id="Common.close"/>
                </button>
            </footer>
        </Modal>
    );
};

DailyQuantitiesModal.propTypes = {
    dailyQuantities: PropTypes.object.isRequired,
    dailyUsedQuantities: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default DailyQuantitiesModal;
