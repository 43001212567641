const FIELD_TYPES = [
    {
        id: 'text',
        type: 'text',
        hasMaxLength: true,
        hasList: false,
        hasMin: false,
        hasMax: false,
        hasRequired: true,
    },
    {
        id: 'firstname',
        type: 'text',
        hasMaxLength: true,
        hasList: false,
        hasMin: false,
        hasMax: false,
        hasRequired: true,
    },
    {
        id: 'lastname',
        type: 'text',
        hasMaxLength: true,
        hasList: false,
        hasMin: false,
        hasMax: false,
        hasRequired: true,
    },
    {
        id: 'email',
        type: 'email',
        hasMaxLength: false,
        hasList: false,
        hasMin: false,
        hasMax: false,
        hasRequired: true,
    },
    {
        id: 'number',
        type: 'number',
        hasMaxLength: false,
        hasMin: true,
        hasMax: true,
        hasList: false,
        hasRequired: true,
    },
    {
        id: 'tel',
        type: 'tel',
        hasMaxLength: false,
        hasList: false,
        hasRequired: true,
        hasMin: false,
        hasMax: false,
    },
    {
        id: 'select',
        type: 'select',
        hasMaxLength: false,
        hasList: true,
        hasRequired: true,
        hasMin: false,
        hasMax: false,
    },
    {
        id: 'radio',
        type: 'radio',
        hasMaxLength: false,
        hasList: true,
        hasRequired: true,
        hasMin: false,
        hasMax: false,
    },
    {
        id: 'checkbox',
        type: 'checkbox',
        hasMaxLength: false,
        hasList: true,
        hasMin: false,
        hasMax: false,
    },
];

export default FIELD_TYPES;