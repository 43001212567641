import React from 'react';
import Modal from "../../components/Modal";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import QRCode from "qrcode.react";
import paths from "../Router/paths";
import {useTranslate} from "../../hooks";

const QRCodeModal = ({campaignId, isOpen, onClose}) => {
    const {t} = useTranslate();
    const url = `https://${window.location.hostname}${paths.CLIENT_PARTICIPATIONS.replace(':id', campaignId)}`;

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                title={t('QRCodeModal.title')}
            >
                <section className="modal-card-body">
                    <div className="card-content">
                        <div className="text-center">
                            <QRCode
                                value={url}
                                renderAs="svg"
                                style={{height: 400, width: 400}}
                                level="H"
                                bgColor='rgba(255,255,255,0)'
                            />
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button className="button" onClick={onClose}>
                        <FormattedMessage id="Common.close"/>
                    </button>
                </footer>
            </Modal>
        </>
    );
};

QRCodeModal.propTypes = {
    campaignId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default QRCodeModal;
