/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {format} from 'date-fns';

import paths from 'pages/Router/paths';
import {usersCleanUp} from 'state/actions/users';
import {useChangeHandler, useFormatMessage, useTranslate} from 'hooks';
import {validateEmail} from 'utils';
import './UserForm.scss';
import DatePicker from '../DatePicker';

const UserForm = ({isEditing, isProfile, userData, action}) => {
    const {loading} = useSelector(
        state => ({
            loading: state.users.loading
        }),
        shallowEqual
    );

    const {t} = useTranslate();

    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(usersCleanUp());
    }, [dispatch]);

    const [user, setUser] = useState(userData);

    const onChangeHandler = useChangeHandler(setUser);

    const onSubmitHandler = event => {
        event.preventDefault();
        dispatch(
            action({...user, isEditing, isProfile})
        );
    };

    let emailInput = {
        modifier: null,
        message: {modifier: null, content: null}
    };

    const invalidEmail = user.email && !validateEmail(user.email);

    const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');

    if (invalidEmail) {
        emailInput = {
            modifier: 'is-danger',
            message: {
                modifier: 'is-danger',
                content: invalidEmailMessage
            }
        };
    }
    ;


    const canSubmit = user.name && user.startDate && user.endDate && !invalidEmail;

    return (
        <>
            <div className="tile is-ancestor">
                <div className="tile is-parent">
                    <div className="card tile is-child">
                        <header className="card-header">
                            <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default"/>
                </span>
                                {t('UserForm.userInfo')}
                            </p>
                        </header>
                        <div className="card-content">
                            <form onSubmit={onSubmitHandler}>
                                {isEditing ? (
                                    <div className="field is-horizontal">
                                        <div className="field-label is-normal">
                                            <label className="label">{t('UserForm.email')}</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="control">
                                                    <input
                                                        type="text"
                                                        readOnly="readOnly"
                                                        className="input is-static"
                                                        value={user.email}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="field is-horizontal">
                                        <div className="field-label is-normal">
                                            <label className="label">{t('UserForm.email')}</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="control">
                                                    <input
                                                        className={`input ${emailInput.modifier}`}
                                                        type="email"
                                                        required
                                                        name="email"
                                                        value={user.email}
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                                {emailInput.message.content && (
                                                    <p
                                                        className={`help is-${emailInput.message.modifier}`}
                                                    >
                                                        {emailInput.message.content}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="field is-horizontal">
                                    <div className="field-label is-normal">
                                        <label className="label">
                                            {t('UserForm.name')}
                                        </label>
                                    </div>
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="control">
                                                <input
                                                    id="name"
                                                    className="input"
                                                    type="text"
                                                    required
                                                    name="name"
                                                    value={user.name}
                                                    onChange={onChangeHandler}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {!isProfile && (
                                    <div className="field has-check is-horizontal">
                                        <div className="field-label">
                                            <label className="label">{t('UserForm.admin')}</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="field">
                                                    <div className="control">
                                                        <label className="b-checkbox checkbox">
                                                            <input
                                                                type="checkbox"
                                                                name="isAdmin"
                                                                onChange={onChangeHandler}
                                                                checked={user.isAdmin}
                                                            />
                                                            <span className="check is-primary"/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {!user.isAdmin &&
                                <>
                                    <div className="field is-horizontal">
                                        <div className="field-label is-normal">
                                            <label className="label">
                                                {t('UserForm.allowedCampaigns')}
                                            </label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="control">
                                                    <input
                                                        id="name"
                                                        className="input"
                                                        type="number"
                                                        required
                                                        name="allowedCampaigns"
                                                        min="0"
                                                        value={user.allowedCampaigns}
                                                        onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field is-horizontal">
                                        <div className="field-label is-normal">
                                            <label className="label">
                                                {t('UserForm.startDate')}
                                            </label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <DatePicker
                                                    name="startDate"
                                                    date={!isEditing ? user.startDate && new Date(user.startDate) : new Date(user.startDate)}
                                                    onChange={(date) => setUser((prevState) => ({
                                                        ...prevState,
                                                        startDate: format(date, 'ccc MMM dd yyyy')
                                                    }))}
                                                    maxDate={user.endDate && new Date(user.endDate)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="field is-horizontal">
                                        <div className="field-label is-normal">
                                            <label className="label">
                                                {t('UserForm.endDate')}
                                            </label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <DatePicker
                                                    name="endDate"
                                                    date={!isEditing ? user.endDate && new Date(user.endDate) : new Date(user.endDate)}
                                                    onChange={(date) => setUser((prevState) => ({
                                                        ...prevState,
                                                        endDate: format(date, 'ccc MMM dd yyyy')
                                                    }))}
                                                    minDate={user.startDate && new Date(user.startDate)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }

                                <hr/>

                                <hr/>
                                <div className="field is-horizontal">
                                    <div className="field-label"/>
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="field is-grouped">
                                                <div className="control">
                                                    <button
                                                        type="submit"
                                                        className={`button is-primary ${loading &&
                                                        'is-loading'}`}
                                                        disabled={!canSubmit}
                                                    >
                                                        <span>{t('UserForm.submit')}</span>
                                                    </button>
                                                </div>
                                                {!isProfile && (
                                                    <Link to={paths.USERS} className="button">
                                                        {t('UserForm.goBack')}
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

UserForm.propTypes = {
    isEditing: PropTypes.bool,
    userData: PropTypes.shape({
        id: PropTypes.string,
        isAdmin: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        logoUrl: PropTypes.string,
    }),
    action: PropTypes.func.isRequired
};

export default UserForm;
