import {useHistory} from 'react-router-dom';
import {useEffect, useMemo, useState} from "react";
import parseQuery from "../utils/parseQuery";

const useQueryParam = (name) => {
    const history = useHistory();
    const param = useMemo(() => {
        const params = parseQuery(history.location.search);
        return params[name]
    }, [history.location.search]);

    return param;
};

export default useQueryParam;