import {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";

const useDeviceToken = () => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        let deviceToken = localStorage.getItem('deviceToken');
        if(!deviceToken){
            deviceToken = uuidv4();
            localStorage.setItem('deviceToken', deviceToken);
        }

        setToken(deviceToken);
    }, []);

    return token;
};

export default useDeviceToken;