import React, {useState, useContext} from 'react';
import {useTranslate} from 'hooks';
import {useSelector} from "react-redux";
import Switch from "react-switch";
import PrizeItem from './PrizeRow';
import PrizeModal from './PrizeModal';
import ConfirmationModal from '../../../components/ConfirmationModal';
import CampaignFormContext from "../CampaignFormContext";
import {CAMPAIGN_STEPS} from "../../../constants/CampaignSteps";
import {newId} from "../../../firebase";
import QUANTITY_MODE from "../../../constants/QuantityMode";
import FormFooter from "../FormFooter";

const createDraftPrize = () => ({
    id: newId(),
    name: '',
    code: '',
    image: '',
    file: null,
    probability: '',
    zones: [],
    quantity: '',
    dailyQuantities: {},
    quantityMode: QUANTITY_MODE.prorata,
    usedQuantity: 0,
    dailyUsedQuantities: {}
});

const PrizesTab = () => {
    const {t} = useTranslate();
    const {campaign, prizes, setPrizes, setCampaign, saveCampaign, setDeletedPrizes, deletedPrizes, probabilitiesSum} = useContext(CampaignFormContext);
    const [editingPrize, setEditingPrize] = useState(null);
    const [deletingPrize, setDeletingPrize] = useState(null);

    const isAdmin = useSelector(state => state.auth.userData.isAdmin);
    const loseRate = ((1 - probabilitiesSum) * 100).toFixed(2);

    const closeDeletingModal = () => setDeletingPrize(null);

    const handleDeletePrize = () => {
        setPrizes(prizes.filter((prize) => prize.id !== deletingPrize.id));
        setDeletedPrizes([...deletedPrizes, deletingPrize.id]);
        closeDeletingModal();
    };

    const handleSavePrize = (data) => {
        let newPrizes;
        const prizeIndex = prizes.findIndex(({id}) => id === data.id);

        if (prizeIndex === -1) {
            newPrizes = [ {...data},...prizes];
        } else {
            newPrizes = prizes.map((prize, index) => index === prizeIndex ? data : prize);
        }

        setPrizes(newPrizes);
        setEditingPrize(null);
    };

    const handleSaveCampaign = event => {
        event.preventDefault();
        saveCampaign(CAMPAIGN_STEPS.prizes);
    };

    const handleAddressStatus = (checked) => {
        setCampaign((prevState) => ({...prevState, isFullWin: checked}));
    };

    return (
        <>
            {prizes.length > 0 && (
                <div className={`notification ${probabilitiesSum > 1 || campaign.isFullWin && probabilitiesSum < 1 ? 'is-danger' : 'is-info is-light'}`}>
                    {campaign.isFullWin && probabilitiesSum < 1 && (
                        <>
                            {t('PrizesForm.fullWinProbabilitiesSum')}
                            <br/>
                        </>
                    )}
                    {probabilitiesSum > 1 && t('PrizesForm.excessiveProbabilities', {probabilitiesSum})}
                    {probabilitiesSum <= 1 && t('PrizesForm.probabilitiesSumMessage', {probabilitiesSum})}
                    {probabilitiesSum < 1 && (
                        <>
                            <br/>
                            {t('PrizesForm.loseRate', {loseRate})}
                        </>
                    )}
                </div>
            )}

            <label className="is-flex is-aligned-center mt-4 mb-4 column is-full">
                <Switch onChange={handleAddressStatus} checked={campaign.isFullWin}/>
                <div className="ml-3">
                    {t('PrizesForm.fullWin')}
                </div>
            </label>

            <button
                type="button"
                className="button is-info mb-5"
                onClick={() => setEditingPrize(createDraftPrize())}
                disabled={probabilitiesSum >= 1 || (campaign.isStarted && !isAdmin)}
            >
                <i className="mdi mdi-plus"/> {t('PrizesForm.addPrize')}
            </button>

            <table className="table">
                <thead>
                <tr>
                    <th>{t('PrizesForm.image')}</th>
                    <th>{t('PrizesForm.name')}</th>
                    <th>{t('PrizesForm.quantity')}</th>
                    <th>{t('PrizesForm.probability')}</th>
                    <th>{t('PrizesForm.actions')}</th>
                </tr>
                </thead>
                <tbody>
                {prizes.map((prize) => (
                    <PrizeItem
                        key={prize.id}
                        prize={prize}
                        onEdit={setEditingPrize}
                        onDelete={setDeletingPrize}
                    />
                ))}
                </tbody>
            </table>

            <FormFooter onSubmit={handleSaveCampaign}/>

            {!!editingPrize && (
                <PrizeModal
                    prize={editingPrize}
                    onClose={() => setEditingPrize(false)}
                    isOpen
                    onSave={handleSavePrize}
                />
            )}

            <ConfirmationModal
                isActive={!!deletingPrize}
                confirmButtonMessage={t('PrizesForm.delete')}
                title={t('PrizesForm.confirm')}
                body={t('PrizesForm.permDelete')}
                cancelButtonMessage={t('PrizesForm.cancel')}
                onConfirmation={handleDeletePrize}
                onCancel={closeDeletingModal}
            />
        </>
    );
};

export default PrizesTab;
