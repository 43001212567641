import { createReducer } from 'redux-act';

import {
  // actions here
  CAMPAIGNS_FETCH_DATA_INIT,
  CAMPAIGNS_FETCH_DATA_SUCCESS,
  CAMPAIGNS_FETCH_DATA_FAIL,
  CAMPAIGNS_CREATE_CAMPAIGN_INIT,
  CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS,
  CAMPAIGNS_MODIFY_CAMPAIGN_INIT,
  CAMPAIGNS_MODIFY_CAMPAIGN_FAIL,
  CAMPAIGNS_MODIFY_CAMPAIGN_SUCCESS,
  CAMPAIGNS_DELETE_CAMPAIGN_INIT,
  CAMPAIGNS_DELETE_CAMPAIGN_FAIL,
  CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS,
  CAMPAIGNS_MODIFY_GIVEN_PRIZE_SUCCESS,
  CAMPAIGNS_DUPLICATE_CAMPAIGN_INIT,
  CAMPAIGNS_DUPLICATE_CAMPAIGN_FAIL,
  CAMPAIGNS_DUPLICATE_CAMPAIGN_SUCCESS
} from 'state/actions/campaigns';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
  duplicated: false
};

export const campaignsReducer = createReducer(
  {
    [CAMPAIGNS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true
    }),
    [CAMPAIGNS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.campaigns,
      loading: false,
      error: null
    }),
    [CAMPAIGNS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [CAMPAIGNS_CREATE_CAMPAIGN_INIT]: (state) => ({
      ...state,
      loading: true
    }),
    [CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      // must add new campaign into allData
      // data: state.data.concat(payload.user),
      error: null,
      success: true
    }),
    [CAMPAIGNS_MODIFY_CAMPAIGN_INIT]: state => ({
      ...state,
      loading: true
    }),
    [CAMPAIGNS_MODIFY_CAMPAIGN_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [CAMPAIGNS_MODIFY_CAMPAIGN_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    [CAMPAIGNS_DELETE_CAMPAIGN_INIT]: state => ({
      ...state,
      loading: true
    }),
    [CAMPAIGNS_DELETE_CAMPAIGN_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter(elem => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true
    }),
    [CAMPAIGNS_MODIFY_GIVEN_PRIZE_SUCCESS]: (state) => ({
      ...state,
      loading: false
    }),
    [CAMPAIGNS_DUPLICATE_CAMPAIGN_INIT]: state => ({
      ...state,
      loading: true
    }),
    [CAMPAIGNS_DUPLICATE_CAMPAIGN_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [CAMPAIGNS_DUPLICATE_CAMPAIGN_SUCCESS]: (state, payload) => ({
      ...state,
      data: [...state.data, payload],
      loading: false,
      error: null,
      duplicated: true
    }),
  },
  initialState
);
