import {createAction} from 'redux-act';
import {toastr} from 'react-redux-toastr';

import {firebaseError, firebaseMessage} from 'utils';
import firebase from 'firebase.js';
import {v4 as uuidv4} from 'uuid';
import {checkUserData} from './auth';

export const CAMPAIGNS_FETCH_DATA_INIT = createAction('CAMPAIGNS_FETCH_DATA_INIT');
export const CAMPAIGNS_FETCH_DATA_SUCCESS = createAction('CAMPAIGNS_FETCH_DATA_SUCCESS');
export const CAMPAIGNS_FETCH_DATA_FAIL = createAction('CAMPAIGNS_FETCH_DATA_FAIL');
export const CAMPAIGNS_CREATE_CAMPAIGN_INIT = createAction('CAMPAIGNS_CREATE_CAMPAIGN_INIT');
export const CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS = createAction('CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS');
export const CAMPAIGNS_MODIFY_CAMPAIGN_INIT = createAction('CAMPAIGNS_MODIFY_CAMPAIGN_INIT');
export const CAMPAIGNS_MODIFY_CAMPAIGN_FAIL = createAction('CAMPAIGNS_MODIFY_CAMPAIGN_FAIL');
export const CAMPAIGNS_MODIFY_CAMPAIGN_SUCCESS = createAction('CAMPAIGNS_MODIFY_CAMPAIGN_SUCCESS');
export const CAMPAIGNS_DELETE_CAMPAIGN_INIT = createAction('CAMPAIGNS_DELETE_CAMPAIGN_INIT');
export const CAMPAIGNS_DELETE_CAMPAIGN_FAIL = createAction('CAMPAIGNS_DELETE_CAMPAIGN_FAIL');
export const CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS = createAction('CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS');
export const CAMPAIGNS_MODIFY_GIVEN_PRIZE_SUCCESS = createAction('CAMPAIGNS_MODIFY_GIVEN_PRIZE_SUCCESS');
export const CAMPAIGNS_DUPLICATE_CAMPAIGN_INIT = createAction('CAMPAIGNS_DUPLICATE_CAMPAIGN_INIT');
export const CAMPAIGNS_DUPLICATE_CAMPAIGN_FAIL = createAction('CAMPAIGNS_DUPLICATE_CAMPAIGN_FAIL');
export const CAMPAIGNS_DUPLICATE_CAMPAIGN_SUCCESS = createAction('CAMPAIGNS_DUPLICATE_CAMPAIGN_SUCCESS');

const uploadFile = async (file, folder = '') => {
    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${folder}/${uuidv4()}.${fileExtension}`;

    return await storageRef.child(`${fileName}`).put(file);
};

export const fetchCampaigns = () => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());
        dispatch(CAMPAIGNS_FETCH_DATA_INIT());
        const {id: userId, isAdmin} = getState().auth.userData;

        try {
            let query = firebase.firestore().collection("campaigns");

            if (!isAdmin) {
                query = query.where("ownerId", "==", userId);
            }

            const snapshot = await query.get();
            const campaigns = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));

            return dispatch(CAMPAIGNS_FETCH_DATA_SUCCESS({campaigns}));
        } catch (error) {
            const errorMessage = firebaseError(error.code);
            toastr.error('', errorMessage);
        }
    };
};

export const createCampaign = ({campaign}, onSuccessCallback, onFailCallback) => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());
        dispatch(CAMPAIGNS_CREATE_CAMPAIGN_INIT());

        const {locale} = getState().preferences;

        try {
            const campaignData = campaign;
            const campaignRef = await firebase.firestore().collection('campaigns').add(campaign);

            if (typeof onSuccessCallback === 'function') {
                onSuccessCallback({id: campaignRef.id, ...campaignData});
            }

            return dispatch(CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS());
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toastr.error('', errorMessage);

            if (typeof onFailCallback === 'function') {
                onFailCallback(error);
            }
        }
    };
};

export const modifyCampaign = (id, {campaign, files, prizes, deletedPrizes}, onSuccessCallback, onFailCallback) => {
    return async (dispatch, getState) => {
        dispatch(CAMPAIGNS_MODIFY_CAMPAIGN_INIT());
        const {locale} = getState().preferences;

        const campaignData = campaign;

        try {
            const fileKeys = Object.keys(files);
            for (let i = 0; i < fileKeys.length; i++) {
                const key = fileKeys[i];
                const file = files[key];

                if (file) {
                    const snap = await uploadFile(file, key);
                    campaignData.files[key] = await snap.ref.getDownloadURL();
                }
            }

            const batch = firebase.firestore().batch();

            const campaignRef = firebase.firestore().collection('campaigns').doc(id);
            const prizesCollection = campaignRef.collection('prizes');
            batch.update(campaignRef, campaignData);

            deletedPrizes.forEach(id => batch.delete(prizesCollection.doc(id)));

            for (let i = 0; i < prizes.length; i++) {
                const prize = {...prizes[i]};

                if (prize.image instanceof File) {
                    const snap = await uploadFile(prize.image, 'prizes');
                    prize.image = await snap.ref.getDownloadURL();
                }

                batch.set(prizesCollection.doc(prize.id), prize, {merge: true});
            }

            await batch.commit();

            if (typeof onSuccessCallback === 'function') {
                onSuccessCallback(campaignData);
            }

            return dispatch(CAMPAIGNS_MODIFY_CAMPAIGN_SUCCESS({campaign: campaignData}));
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toastr.error('', errorMessage);

            if (typeof onFailCallback === 'function') {
                onFailCallback(error);
            }

            return dispatch(CAMPAIGNS_MODIFY_CAMPAIGN_FAIL({error: errorMessage}));
        }
    };
};

export const duplicateCampaign = (id) => {
  return async(dispatch, getState) => {
      dispatch(CAMPAIGNS_DUPLICATE_CAMPAIGN_INIT());
      const {locale} = getState().preferences;

      try {
          const fb = firebase.firestore();

          const campaignRef = fb.collection("campaigns").doc(id);
          const prizesCollection = await campaignRef.collection('prizes').get();

          const campaign= await campaignRef.get();

          const currentName= campaign.data().name;
          const name= `${currentName}_duplicate`;
          const duplicateData= {...campaign.data(), name};

          const newCampaign = await firebase.firestore().collection('campaigns').add({}).then(item => {
              const newData= { ...duplicateData, id: item.id, isStarted: false, zeroPrizes: false, usedQuantity: 0 }; // If campaign is started it's restarted
              item.set(newData);

              prizesCollection.forEach((doc) => {
                   fb.collection("campaigns").doc(item.id).collection('prizes').add({}).then(prize => prize.set({...doc.data(), id: prize.id, usedQuantity: 0, dailyUsedQuantities: []}));
              });

              return newData;
          });

          return dispatch(CAMPAIGNS_DUPLICATE_CAMPAIGN_SUCCESS(newCampaign));
      }catch (error) {
          const errorMessage = firebaseError(error.code, locale);
          toastr.error('', errorMessage);
          return dispatch(CAMPAIGNS_DUPLICATE_CAMPAIGN_FAIL({error: errorMessage}));
      }
  };
};

export const deleteCampaign = (id) => {
    return async (dispatch, getState) => {
        dispatch(CAMPAIGNS_DELETE_CAMPAIGN_INIT());
        const {locale} = getState().preferences;

        try {
            const fb = firebase.firestore();
            const campaignRef = fb.collection("campaigns").doc(id);
            const prizes = await campaignRef.collection('prizes').get();

            const batch = fb.batch();
            prizes.docs.forEach((doc) => batch.delete(doc.ref));
            batch.delete(campaignRef);
            await batch.commit();
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toastr.error('', errorMessage);
            return dispatch(CAMPAIGNS_DELETE_CAMPAIGN_FAIL({error: errorMessage}));
        }

        toastr.success('', firebaseMessage('Campaign.deleted', locale));
        return dispatch(CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS({id}));
    };
};