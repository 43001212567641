import React, {useContext, useState} from 'react';
import FileInput from '../Fields/FileInput';
import {useChangeHandler, useTranslate} from 'hooks';
import Modal from "../../../components/Modal";
import CampaignFormContext from "../CampaignFormContext";
import DailyQuantitiesModal from "./DailyQuantitiesModal";
import useStateSync from "../../../hooks/useStateSync";
import QUANTITY_MODE from "../../../constants/QuantityMode";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import {prizeSchema} from "../../../validators/Campaign";
import {useSelector} from "react-redux";

const PrizeModal = ({isOpen, onSave, prize, onClose}) => {
    const {t} = useTranslate();
    const {campaign, availableZones} = useContext(CampaignFormContext);
    const [localPrize, setLocalPrize] = useStateSync(prize);
    const [isDailyQuantityOpen, setDailyQuantityOpen] = useState(false);
    const [errors, setErrors] = useState(null);

    const isAdmin = useSelector(state => state.auth.userData.isAdmin);

    const handleSave = async () => {
        try {
            setErrors(null);
            const data = await prizeSchema.validate(localPrize, {abortEarly: false});
            onSave(data);
        } catch (err) {
            setErrors(err);
        }
    };

    const handleChange = useChangeHandler(setLocalPrize);

    const handleChangeZones = (zone, checked) => {
        setLocalPrize({
            ...localPrize,
            zones: checked ? [...localPrize.zones, zone] : localPrize.zones.filter(existingZone => existingZone !== zone),
        });
    };

    const handleDailyQuantitiesChange = (dailyQuantities) => {
        setLocalPrize({
            ...localPrize,
            dailyQuantities,
        });

        setDailyQuantityOpen(false);
    };

    const currentAvailableZones = [...availableZones, ...prize.zones];

    return (
        <>
            <Modal
                isOpen={isOpen && !isDailyQuantityOpen}
                onClose={onClose}
                title={t(prize.name ? 'PrizeForm.editTitle' : 'PrizeForm.addTitle', {name: prize.name})}
            >
                <section className="modal-card-body">
                    <div className="card-content">
                        {errors?.errors && (
                            <div className="notification is-danger">
                                {errors.errors.map(error => <p>{error}</p>)}
                            </div>
                        )}
                        <div className="field">
                            <label htmlFor="name" className="label required">
                                <FormattedMessage id="PrizeForm.name"/>
                            </label>
                            <div className="control">
                                <input
                                    id="name"
                                    className="input"
                                    type="text"
                                    required
                                    name="name"
                                    value={localPrize.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="code" className="label required">
                                <FormattedMessage id="PrizeForm.code"/>
                            </label>
                            <div className="control">
                                <input
                                    id="code"
                                    type="text"
                                    className="input"
                                    required
                                    name="code"
                                    value={localPrize.code}
                                    disabled={campaign.isStarted && !isAdmin}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <FileInput
                            name="image"
                            value={localPrize.image}
                            onChange={handleChange}
                            label={t('PrizeForm.image')}
                        />
                        <div className="field">
                            <label htmlFor="probability" className="label required">
                                <FormattedMessage id="PrizeForm.probability"/>
                            </label>
                            <div className="control">
                                <input
                                    id="probability"
                                    className="input"
                                    type="number"
                                    min="0"
                                    step="0.001"
                                    max={1}
                                    required
                                    name="probability"
                                    disabled={campaign.isStarted && !isAdmin}
                                    value={localPrize.probability}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label required">
                                <FormattedMessage id="PrizeForm.positions"/>
                            </label>
                            <div className="control">
                                {currentAvailableZones.sort((a, b) => a - b).map((zone) => (
                                    <label htmlFor={`zone-${zone}`} key={zone} className="tag checkbox ml-1">
                                        <input
                                            id={`zone-${zone}`}
                                            type="checkbox"
                                            checked={localPrize.zones?.includes(zone)}
                                            onChange={(e) => handleChangeZones(zone, e.target.checked)}
                                        />
                                        <div className="ml-2 is-inline-block">{zone}</div>
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div className="Field">
                            <b className="label required">
                                <FormattedMessage id="PrizeForm.quantityMode"/>
                            </b>
                            <div className="control">
                                <label htmlFor="daily" className="radio">
                                    <input
                                        id="daily"
                                        type="radio"
                                        name="quantityMode"
                                        value="daily"
                                        onChange={handleChange}
                                        checked={localPrize.quantityMode === QUANTITY_MODE.daily}
                                    />
                                    <div className="ml-2 is-inline-block">
                                        <FormattedMessage id="PrizeForm.dailyQuantity"/>
                                    </div>
                                </label>
                                <label htmlFor="prorata" className="radio ml-4">
                                    <input
                                        id="prorata"
                                        type="radio"
                                        name="quantityMode"
                                        value="prorata"
                                        onChange={handleChange}
                                        checked={localPrize.quantityMode === QUANTITY_MODE.prorata}
                                    />
                                    <div className="ml-2 is-inline-block">
                                        <FormattedMessage id="PrizeForm.prorata"/>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="mt-2">
                            {localPrize.quantityMode === QUANTITY_MODE.prorata ? (
                                <div className="field">
                                    <label htmlFor="quantity" className="label required">
                                        <FormattedMessage id="PrizeForm.globalQuantity"/>
                                    </label>
                                    <div className="control">
                                        <input
                                            id="quantity"
                                            className="input"
                                            type="number"
                                            name="quantity"
                                            value={localPrize.quantity}
                                            required
                                            onChange={handleChange}
                                        />
                                        {campaign.isStarted && (
                                            <p className="help">
                                                <FormattedMessage id="PrizeForm.availableQuantity"
                                                                  values={{count: localPrize.quantity - prize.usedQuantity}}/>
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <button
                                    className="button is-info"
                                    onClick={() => setDailyQuantityOpen(true)}
                                >
                                    <FormattedMessage id="PrizeForm.dailyQuantitiesButton"/>
                                </button>
                            )}
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button className="button is-success" onClick={handleSave}>
                        <FormattedMessage id="PrizeForm.save"/>
                    </button>
                    <button className="button" onClick={onClose}>
                        <FormattedMessage id="PrizeForm.close"/>
                    </button>
                </footer>
            </Modal>

            <DailyQuantitiesModal
                dailyUsedQuantities={prize.dailyUsedQuantities}
                dailyQuantities={localPrize.dailyQuantities}
                isOpen={isDailyQuantityOpen}
                onSave={handleDailyQuantitiesChange}
                onClose={() => setDailyQuantityOpen(false)}
            />
        </>
    );
};

PrizeModal.propTypes = {
    prize: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PrizeModal;
