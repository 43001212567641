import React, {useState, useMemo} from 'react';
import classNames from 'classnames';
import {useTranslate} from 'hooks';
import {useParams} from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from 'components/Modal';
import {CSVLink} from "react-csv";
import {FormattedMessage} from "react-intl";
import {format} from "date-fns";
import PropTypes from 'prop-types';
import Table from '../../components/Table';
import slugify from "../../utils/slugify";
import firebaseDateToDate from "../../utils/firebaseDateToDate";
import classes from './index.module.scss';
import firebase from "../../firebase";

const List = ({campaign, participations}) => {
    const {id} = useParams();
    const {t, formatDate} = useTranslate();
    const [search, setSearch] = useState('');
    const [editing, setEditing] = useState(null);
    const [openedParticipation, setOpenedParticipation] = useState(null);

    const {headers: csvHeaders, data: csvData} = useMemo(() => {
        const headers = [
            {label: t('ParticipationList.code'), key: 'code'},
            {label: t('ParticipationList.date'), key: 'date'},
            {label: t('ParticipationList.prize'), key: 'prize'},
            {label: t('ParticipationList.isGiven'), key: 'isGiven'},
            {label: t('ParticipationList.clientId'), key: 'clientId'},
            ...campaign.fields.map(field => ({label: field.name, key: slugify(field.name)}))
        ];

        const data = participations.map((item) => {
            const row = {
                code: item.code,
                date: format(firebaseDateToDate(item.createdAt), 'dd/MM/yyyy HH:mm'),
                prize: item.prize ? `${item.prize?.name} (${item.prize.code})` : '--',
                isGiven: item.isGiven ? 'Oui' : 'Non',
                clientId: item.clientId
            };

            item.data.forEach((answer) => row[slugify(answer.name)] = answer.value);

            return row;
        });

        return {headers, data};
    }, [participations, campaign.fields]);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const handleChangeGiven = async (e) => {
        e.preventDefault();
        setEditing(e.target.value);
        await firebase.firestore()
            .collection("campaigns")
            .doc(id)
            .collection('participations')
            .doc(e.target.value)
            .update({isGiven: e.target.checked});
        setEditing(null);
    };

    const fieldTypes = campaign.fields.map(field => field.id);
    const columns = [
        {
            Header: t('ParticipationList.clientId'),
            accessor: 'clientId'
        },
        {
            Header: t('ParticipationList.code'),
            accessor: 'code'
        },
        {
            Header: t('ParticipationList.date'),
            accessor: 'date',
            Cell: ({row}) => (
                <small className="has-text-grey is-abbr-like">
                    {format(firebaseDateToDate(row.original.createdAt), 'dd/MM/yyyy HH:mm')}
                </small>
            )
        },
        {
            Header: t('ParticipationList.prize'),
            accessor: (row) => row.prize ? <>{row.prize?.name} ({row.prize.code}) {row.prize?.isManual && <span className="has-text-info">{t('ParticipationList.isManual')}</span>} </> : '--',
        },
        {
            Header: t('ParticipationList.isGiven'),
            accessor: 'isGiven',
            Cell: ({row}) => (editing === row.original.id) ? (
                <ClipLoader size={20} color="#00C4A7"/>
            ) : (
                <>
                    <input onChange={handleChangeGiven} type="checkbox" value={row.original.id}
                           checked={row.original.isGiven}/>
                </>
            )
        },
        campaign.isFormEnabled && {
            Header: t('ParticipationList.data'),
            accessor: 'data',
            Cell: ({row}) => (
                <button className="button is-link" onClick={() => setOpenedParticipation(row.original)}>
                    <span className="icon is-small mr-1">
                        <i className="mdi mdi-file-find"/>
                    </span>
                    Voir les données
                </button>
            )
        },
        fieldTypes.includes('firstname') && {
            Header: t('ParticipationList.firstname'),
            accessor: 'firstName',
            Cell: ({row}) => <div>{row.original.data.find((item) => item.id === 'firstname')?.value}</div>
        },
        fieldTypes.includes('lastname') && {
            Header: t('ParticipationList.lastname'),
            accessor: 'lastname',
            Cell: ({row}) => <div>{row.original.data.find((item) => item.id === 'lastname')?.value}</div>
        },
        fieldTypes.includes('email') && {
            Header: t('ParticipationList.email'),
            accessor: 'email',
            Cell: ({row}) => <div>{row.original.data.find((item) => item.id === 'email')?.value}</div>
        },
        fieldTypes.includes('tel') && {
            Header: t('ParticipationList.tel'),
            accessor: 'tel',
            Cell: ({row}) => <div>{row.original.data.find((item) => item.id === 'tel')?.value}</div>
        },
    ].filter(v => v);

    const filteredData = useMemo(() => {
        return participations.filter((item) => !search?.trim() || [
            item.code,
            item.clientId,
            formatDate(firebaseDateToDate(item.createdAt)),
            item.prize?.name ?? '--',
            ...item.data.map((answer) => answer.value)
        ].filter(v => v).some(field => field?.toLowerCase().includes(search.toLowerCase())));
    }, [participations, search]);

    return (
        <>
            <div className="card has-table has-mobile-sort-spaced">
                <header className={classNames('card-header', classes.cardHeader)}>
                    <p className="card-header-title">
                        <span>{t('Users.search')}</span>
                        <input
                            type="text"
                            className="input"
                            value={search}
                            onChange={e => handleSearch(e)}
                        />
                    </p>
                    <CSVLink filename={campaign.name} className="button" data={csvData} headers={csvHeaders}>
                        <FormattedMessage id="ParticipationList.csv"/>
                    </CSVLink>
                </header>
                <div className="b-table">
                    <Table columns={columns} data={filteredData}/>
                </div>
            </div>
            <Modal
                isOpen={!!openedParticipation}
                onClose={() => setOpenedParticipation(null)}
                title={t('ParticipationList.modalTitle')}
            >
                <section className="modal-card-body">
                    <div className="card-content">
                        {openedParticipation && openedParticipation.data.map((element) => (
                            <div key={slugify(element.name)}>
                                <b className="is-inline-block mr-1">{element.name}:</b>
                                {element.value}
                            </div>
                        ))}
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button className="button" onClick={() => setOpenedParticipation(null)}>
                        <FormattedMessage id="Common.close"/>
                    </button>
                </footer>
            </Modal>
        </>
    );
};

List.propTypes = {
    participations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        code: PropTypes.string,
        date: PropTypes.shape({seconds: PropTypes.number, nanoseconds: PropTypes.number}),
        prize: PropTypes.shape({id: PropTypes.string, name: PropTypes.string}),
        isGiven: PropTypes.bool,
        clientId: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            value: PropTypes.string
        }))
    })).isRequired,
    campaign: PropTypes.shape({
        id: PropTypes.string,
        isFormEnabled: PropTypes.bool,
        fields: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            type: PropTypes.string,
        })),
    }).isRequired,
};

export default List;
