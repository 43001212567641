import React, {useContext} from 'react';
import {convertToRaw, EditorState} from 'draft-js';
import {useChangeHandler, useTranslate} from 'hooks';
import draftToHtml from 'draftjs-to-html';
import {FormattedMessage} from "react-intl";
import Switch from 'react-switch';
import FileInput from '../Fields/FileInput';
import CampaignFormContext from "../CampaignFormContext";
import WysiwygEditor from "../../../components/WysiwygEditor";
import {CAMPAIGN_STEPS} from "../../../constants/CampaignSteps";
import FormFooter from "../FormFooter";
import FILE_INPUTS from "../../../constants/FileInputs";
import LABELS from "../../../constants/Labels";
import EMAIL_LABELS from "../../../constants/SendEmailLabels";

const InterfaceTab = () => {
    const {t} = useTranslate();
    const {campaign, setCampaign, files, setFiles, saveCampaign} = useContext(CampaignFormContext);

    const onChangeHandler = useChangeHandler(setCampaign);

    const onFileChangedHandler = (event, type) => {
        const file = event.target.files[0];

        setFiles({
            ...files,
            [type]: file
        });
    };

    const onSubmitHandler = event => {
        event.preventDefault();
        saveCampaign(CAMPAIGN_STEPS.interface, CAMPAIGN_STEPS.fields);
    };

    const handleLabelChange = (key, value) => {
        setCampaign(prevState => ({
            ...prevState,
            labels: {
                ...prevState.labels,
                [key]: (value instanceof EditorState)
                    ? draftToHtml(convertToRaw(value.getCurrentContent()))
                    : value,
            }
        }));
    };

    const handleSendEmailChange = (checked) => {
        setCampaign((prevState) => ({...prevState, isSendEmailEnabled: checked}));
    };

    return (
        <form onSubmit={onSubmitHandler}>
            <div className="columns is-multiline mb-5">
                {FILE_INPUTS.map(({key, accept, required}) => (
                    <div className="column is-one-third">
                        <FileInput
                            key={key}
                            label={t(`InterfaceForm.${key}`)}
                            onChange={(event) => onFileChangedHandler(event, key)}
                            value={campaign.files?.[key]}
                            required={!campaign.files?.[key] && required}
                            accept={accept}
                        />
                    </div>
                ))}
            </div>

            <div className="field">
                <label className="label required">
                    <FormattedMessage id="InterfaceForm.zones"/>
                </label>
                <div className="control">
                    <input
                        id="zones"
                        className="input"
                        type="number"
                        required
                        name="zones"
                        min={1}
                        value={campaign.zones}
                        onChange={onChangeHandler}
                    />
                </div>
            </div>

            {LABELS.map(({key, ...config}) => (
                <div className="field">
                    <label className={`label ${config.required && 'required'}`}>
                        {t(`InterfaceForm.${key}`)}
                    </label>
                    <div className="control">
                        {config.type === 'wysiwyg' ? (
                            <WysiwygEditor
                                defaultValue={campaign.labels?.[key]}
                                onEditorStateChange={(editorState) => handleLabelChange(key, editorState)}
                            />
                        ) : (
                            <input
                                required
                                name={key}
                                className="input"
                                value={campaign.labels?.[key]}
                                onChange={(event) => handleLabelChange(key, event.target.value)}
                                {...config}
                            />
                        )}
                    </div>
                </div>
            ))}

            <label className="is-flex is-aligned-center mt-4 mb-4">
                <Switch onChange={handleSendEmailChange} checked={campaign.isSendEmailEnabled}/>
                <div className="ml-3">
                    {t('InterfaceForm.sendEmailRequest')}
                </div>
            </label>

            {campaign.isSendEmailEnabled && (
              EMAIL_LABELS.map(({key, label, ...config}) => (
                <div key={key} className="field">
                    <label className={`label ${config.required && 'required'}`}>
                        {t(label)}
                    </label>
                    <div className="control">
                        {config.type === 'wysiwyg' ? (
                          <WysiwygEditor
                            defaultValue={campaign.labels?.[key]}
                            onEditorStateChange={(editorState) => handleLabelChange(key, editorState)}
                          />
                        ) : (
                          <input
                            required
                            name={key}
                            className="input"
                            value={campaign.labels?.[key]}
                            onChange={(event) => handleLabelChange(key, event.target.value)}
                            {...config}
                          />
                        )}
                    </div>
                </div>
              ))
            )}
            <FormFooter/>
        </form>
    );
};

export default InterfaceTab;
