import React, {useRef} from 'react';
import useOutsideClick from "../hooks/useOutsideClick";
import PropTypes from 'prop-types';

const Modal = ({isOpen, closeWhenOutsideClick, onClose, title, children}) => {
    const ref = useRef();

    useOutsideClick(ref, () => {
        if (isOpen && closeWhenOutsideClick){
            onClose();
        }
    });

    return (
        <div className={`modal ${isOpen && 'is-active'}`}>
            <div className="modal-background"/>
            <div className="modal-card" ref={ref}>
                <header className="modal-card-head">
                    <p className="modal-card-title">{title}</p>
                    <button type="button" className="delete" aria-label="close" onClick={onClose}/>
                </header>
                {children}
            </div>
        </div>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeWhenOutsideClick: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

Modal.defaultProps = {
  closeWhenOutsideClick: true
};

export default Modal;
