import React, {useContext, useState} from 'react';
import {useTranslate} from 'hooks';
import DragList from './DragList';
import FieldForm from './FieldForm';
import Switch from "react-switch";
import CampaignFormContext from "../CampaignFormContext";
import {CAMPAIGN_STEPS} from "../../../constants/CampaignSteps";
import FormFooter from "../FormFooter";
import FORM_POSITION from "../../../constants/FormPosition";

const FieldsTab = () => {
    const {t} = useTranslate();
    const {campaign, setCampaign, saveCampaign} = useContext(CampaignFormContext);

    /**
     * Just a trick to reset initial form data
     */
    const [emptyField, setEmptyField] = useState({id: 'text', type: 'text', name: ''});
    const resetEmptyField = () => setEmptyField({...emptyField});

    const saveField = (data, index) => {
        let fields = [...campaign.fields];

        if (![null, undefined].includes(index)) {
            fields = fields.map((field, i) => {
                if (index === i) {
                    return data;
                }

                return field;
            });
        } else {
            fields.push(data);
            resetEmptyField();
        }

        setCampaign({
            ...campaign,
            fields,
        });
    };

    const deleteField = (index) => {
        setCampaign(prevState => ({
            ...prevState,
            fields: campaign.fields.filter((item, i) => i !== index)
        }));
    };

    const handleSubmit = event => {
        event.preventDefault();
        saveCampaign(CAMPAIGN_STEPS.fields, CAMPAIGN_STEPS.prizes);
    };

    const handleEnabledChange = (checked) => {
        setCampaign((prevState) => ({...prevState, isFormEnabled: checked}));
    };

    const handleChangePosition = (value) => {
        setCampaign((prevState) => ({...prevState, formPosition: value}));
    };

    return (
        <>
            <label className="is-flex is-aligned-center">
                <Switch onChange={handleEnabledChange} checked={campaign.isFormEnabled}/>
                <div className="ml-3">
                    {t('FieldsForm.isFormEnabled')}
                </div>
            </label>
            {campaign.isFormEnabled && (
                <div className="is-flex-desktop-only is-aligned-center mt-5">
                    <b className="is-block mr-3">Position:</b>
                    <div className="control">
                        <label className="radio">
                            <input
                                type="radio"
                                name="formPosition"
                                checked={campaign.formPosition === FORM_POSITION.start}
                                onChange={() => handleChangePosition(FORM_POSITION.start)}
                            />
                            <div className="ml-2 is-inline-block">{t('FieldsForm.startPosition')}</div>
                        </label>
                        <div className="is-inline-block ml-3">
                            <label className="radio">
                                <input
                                    type="radio"
                                    name="formPosition"
                                    checked={campaign.formPosition === FORM_POSITION.end}
                                    onChange={() => handleChangePosition(FORM_POSITION.end)}
                                />
                                <div className="ml-2 is-inline-block">{t('FieldsForm.endPosition')}</div>
                            </label>
                        </div>
                    </div>
                </div>
            )}
            {campaign.isFormEnabled && (
                <>
                    <div className="box mt-5">
                        <h5 className="title is-5 mb-0">
                            {t('FieldsForm.addTitle')}
                        </h5>
                        <hr className="mt-4"/>
                        <FieldForm
                            data={emptyField}
                            onSubmit={saveField}
                        />
                    </div>
                    <DragList onSave={saveField} onDelete={deleteField}/>
                </>
            )}
            <FormFooter onSubmit={handleSubmit}/>
        </>
    );
};

export default FieldsTab;
