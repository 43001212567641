export default {
    LOGIN: '/',
    USERS: '/admin/utilisateurs',
    ADD_USER: '/admin/utilisateurs/new',
    MODIFY_USER: '/admin/utilisateurs/:id',
    PROFILE: '/admin/profil',
    RESET_PASSWORD: '/admin/mot-de-passe-oublie',
    ADD_CAMPAIGN: '/admin/campagnes/ajouter',
    MODIFY_CAMPAIGN: '/admin/campagnes/:id/:tab?',
    CAMPAIGN_LIST: '/admin/campagnes',
    CAMPAIGN_CONSULTATION: '/admin/campagne/:id/view',
    CAMPAIGN_SHOWCASE: '/vitrine/:id',
    CAMPAIGN_CLIENT: '/campagne/:id',
    CLIENT_PARTICIPATIONS: '/campagne/:id/vos-lots',
    LOSE_GAME: '/campagne/:id/perdu',
    PUBLIC_ERROR: '/error',
};
