import React, {useContext, useState} from 'react'
import ReactDragListView from 'react-drag-listview';
import FieldItem from "./FieldItem";
import CampaignFormContext from "../CampaignFormContext";
import PropTypes from 'prop-types';

const DragList = ({onSave, onDelete, fieldEdition}) => {
    const {campaign, setCampaign} = useContext(CampaignFormContext);
    const [editingIndex, setEditingIndex] = useState(null);

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            if (fieldEdition) {
                return;
            }

            const data = [...campaign.fields];
            const item = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, item);
            setCampaign(prevState => ({...prevState, fields: data}))
        },
        nodeSelector: 'li',
        handleSelector: 'a'
    };

    return (
        <ReactDragListView {...dragProps}>
            <ul>
                {campaign.fields.map((item, index) => <FieldItem
                    key={index}
                    editingIndex={editingIndex}
                    setEditing={setEditingIndex}
                    onSave={onSave}
                    onDelete={onDelete}
                    index={index}
                    data={item}
                />)}
            </ul>
        </ReactDragListView>
    );
};

DragList.propTypes = {
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  fieldEdition:  PropTypes.bool.isRequired
};

export default DragList;
