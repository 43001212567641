import React, {useEffect} from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';

const PublicRoute = ({path, component: Component}) => {
    useEffect(() => {
        document.documentElement.classList.remove('has-aside-left', 'has-navbar-fixed-top');
        return () => document.documentElement.classList.add('has-aside-left', 'has-navbar-fixed-top');
    }, []);

    return (
        <Route
            exact
            path={path}
            render={() => <Component/>}
        />
    );
};

PublicRoute.propType = {
    path: PropTypes.string.isRequired,
    component: PropTypes.element.isRequired
};

export default PublicRoute;
