import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import useAsyncEffect from "./useAsyncEffect";
import firebase from "../firebase";
import useDeviceToken from "./useDeviceToken";
import paths from "../pages/Router/paths";

const useCachedParticipations = (campaignId) => {
    const key = `participations_${campaignId}`;
    const [participations, setParticipations] = useState([]);

    useAsyncEffect(async () => {
        const cache = localStorage.getItem(key);
        const cachedParticipations = cache ? JSON.parse(cache) : [];
        setParticipations(cachedParticipations);
    }, [campaignId]);

    const addParticipation = (participation) => {
        const updatedParticipations = [...participations, participation];
        localStorage.setItem(key, JSON.stringify(updatedParticipations));
        setParticipations(updatedParticipations);
    };

    return [participations, addParticipation];
};

export default useCachedParticipations;