import {useEffect, useState} from 'react';

const useStateSync = (prop) => {
    const [state, setState] = useState(prop);

    useEffect(() => {
        setState(prop);
    }, [prop]);

    return [state, setState];
};

export default useStateSync;