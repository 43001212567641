import React from 'react';
import classNames from 'classnames';

import classes from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="level-right">
            <div className="level-item">
              © 2020 <span>&nbsp; <a href="https://happywheel.fr/" target="_blank">Happy wheel</a> par <a href="https://www.pixil.fr/" target="_blank">Pixil</a></span>
            </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;