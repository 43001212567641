import React, {useRef, useLayoutEffect, useCallback} from "react";
import {
    RouletteBox,
    RouletteBoard,
    HighLightArea,
    Pointer,
    RouletteContainer,
    general_theme
} from "./styleComp";
import {TweenLite, TweenMax, Power2} from 'gsap';
import {ThemeProvider} from 'styled-components';

const Roulette = ({
  onComplete,
  onReset,
  targetZone,
  nbZones,
  rouletteBackground,
  highlightImage,
  rouletteOverlay,
  pointerImage
}) => {
    const UBoardRef = useRef();
    const OBoardRef = useRef();
    const HighlightRef = useRef();

    useLayoutEffect(() => {
        if (targetZone) {
            run();
        } else {
            reset();
        }
    }, [targetZone]);

    const run = useCallback(() => {
        TweenLite.to([UBoardRef.current, OBoardRef.current], 3, {
            rotation: 360 * 10,
            ease: Power2.easeIn,
            onComplete: () => {
                const inversedZone = nbZones - (targetZone - 1);
                TweenLite.to([UBoardRef.current, OBoardRef.current], 3, {
                    rotation: 360 * 15 + (360 * inversedZone) / nbZones,
                    ease: Power2.easeOut,
                    onComplete: () => {
                        Roulette.highlightAnimation = TweenMax.to(HighlightRef.current, 0.5, {
                            alpha: 1,
                            yoyo: true,
                            repeat: -1
                        });

                        if ('function' === typeof onComplete) {
                            onComplete();
                        }
                    }
                })
            }
        })
    }, [UBoardRef.current, OBoardRef.current, HighlightRef.current, targetZone, nbZones, onComplete]);

    const reset = useCallback(() => {
        TweenLite.to([UBoardRef.current, OBoardRef.current], 0, {
            rotation: 0,
            ease: Power2.easeIn,
            onComplete: () => {
                if(Roulette.highlightAnimation){
                    Roulette.highlightAnimation.pause();
                    TweenMax.to(HighlightRef.current, 0.5, {
                        opacity: 0,
                    })
                }

                if ('function' === typeof onReset) {
                    onReset();
                }
            }
        });
    }, [UBoardRef.current, OBoardRef.current, HighlightRef.current, onReset]);

    return (
        <ThemeProvider theme={Object.assign({}, general_theme)}>
            <RouletteContainer className="roulette-container">
                <RouletteBox
                    className="Roulette-box"
                >
                    <RouletteBoard
                        ref={UBoardRef}
                        Roulette_img={rouletteBackground}
                        className="Roulette-board-u"
                    />
                    <HighLightArea
                        ref={HighlightRef}
                        highlight_img={highlightImage}
                        className="highlight-area"
                    />
                    <RouletteBoard
                        ref={OBoardRef}
                        Roulette_img={rouletteOverlay}
                        className="Roulette-board-o"
                    />
                    <Pointer pointer_img={pointerImage} className="pointer"/>
                </RouletteBox>
            </RouletteContainer>
        </ThemeProvider>
    )
}

export default Roulette;
