import React, {useMemo, useState} from 'react';
import {useParams, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {addDays} from 'date-fns';
import CampaignFormContext from "./CampaignFormContext";
import CampaignFormLayout from "./CampaignFormLayout";
import {createCampaign, modifyCampaign} from "../../state/actions/campaigns";
import CAMPAIGN_SCHEMAS from "../../validators/Campaign";
import {isGreatestStep, CAMPAIGN_STEPS} from "../../constants/CampaignSteps";
import dateToFirebaseDate from "../../utils/dateToFirebaseDate";
import FORM_POSITION from "../../constants/FormPosition";
import paths from "../Router/paths";

const CampaignCreate = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [campaign, setCampaign] = useState({
        name: '',
        step: CAMPAIGN_STEPS.general,
        ownerId: null,
        isStarted: false,
        createdAt: dateToFirebaseDate(new Date()),
        startDate: dateToFirebaseDate(new Date()),
        endDate: dateToFirebaseDate(addDays(new Date(), 7)),
        maxParticipationsPerUser: 0,
        intervalParticipationPerUser: 0,
        usedQuantity: 0,
        dailyUsedQuantities: {},
        zones: 1,
        labels: {
            "color": "#000000",
            "titleFont": "Nunito",
            "rgpd": "En continuant, je reconnais avoir lu et accepté les Conditions Générales d‘Utilisation et les règles du jeu.",
            "cgu": "Conditions Générales d‘Utilisation et règles du jeu",
            "formMetaTitle": "Qui êtes-vous ? - Happy Wheel",
            "formTitle": "Qui êtes-vous ?",
            "submitForm": "Continuer",
            "playMetaTitle": "Jouer - Happy Wheel",
            "prizesMetaTitle": "Vos lots - Happy Wheel",
            "prizesListTitle": "Vos lots",
            "noPrize": "<p style=\"text-align: center;\">Vous n'avez actuellement aucun lot.</p>",
            "errorMetaTitle": "Erreur - Happy Wheel",
            "errorTitle": "Une erreur est survenue",
            "errorDefault": "<p style=\"text-align: center;\">Une erreur est survenue, veuillez réessayer.</p>",
            "campaign/max-games-reached": "<p style=\"text-align: center;\">Vous avez atteint le nombre de participations maximum autorisé par personne.</p>",
            "campaign/play-interval": "<p style=\"text-align: center;\">Veuillez patienter avant de pouvoir participer à nouveau.</p>",
            "campaign/winnerSubject": "[code] | [firstname]. Felicitations! [prizeName]",
            "campaign/winnerEmail": "<p style=\"text-align: center;\">Felicitations [firstname] vous avez gagné : [prizeName] ! <ul><li style=\"text-align: left;\"><strong>Lot :</strong> [prizeName]</li><li><strong>Code :</strong>[code]</li></ul><p style=\"text-align: center;\">[image]</p> </p>",
            "campaign/loserSubject": "[firstname]. Merci pour votre participation",
            "campaign/loserEmail": "<p style=\"text-align: center;\">[firstname] Bonne chance pour la suite</p>",
        },
        fields: [
            {
                id: 'firstname',
                type: 'text',
                name: 'Prénom',
                required: true
            },
            {
                id: 'lastname',
                type: 'text',
                name: 'Nom',
                required: true
            },
            {
                id: 'email',
                type: 'email',
                name: 'Adresse email',
                required: true
            },
            {
                id: 'tel',
                type: 'tel',
                name: 'Téléphone',
                required: true
            },
            {
                id: 'text',
                type: 'text',
                name: 'Code postal',
                required: true
            }
        ],
        isFormEnabled: false,
        formPosition: FORM_POSITION.start,
        files: {},
        fullWin: false
    });
    const [files, setFiles] = useState({});
    const [prizes, setPrizes] = useState([]);
    const [deletedPrizes, setDeletedPrizes] = useState([]);
    const [errors, setErrors] = useState(null);

    const probabilitiesSum = useMemo(() => prizes.reduce((acc, prize) => acc.add(prize.probability), 0), [prizes]);

    const availableZones = useMemo(() => {
        const zones = Array.from(Array(parseInt(campaign.zones || 1)), (_, i) => i + 1);

        // Find already used zones
        const usedZones = prizes.flatMap(prize => prize.zones).map(zone => parseInt(zone));

        return zones.filter(zone => !usedZones.includes(zone));
    }, [campaign.zones, prizes]);

    const saveCampaign = async (editingStep, nextStep) => {
        const usedPriceZones = [].concat(...prizes.map(i => i.zones)).length;
        const notFullWin = campaign.isFullWin && usedPriceZones !== campaign.zones;

        try {
            setErrors(null);
            const schema = campaign.isCompleted ? CAMPAIGN_SCHEMAS[CAMPAIGN_STEPS.prizes] : CAMPAIGN_SCHEMAS[editingStep];
            const data = await schema.validate({
                campaign,
                files,
                prizes,
                probabilitiesSum,
                availableZones,
                notFullWin
            }, {context: {campaign, probabilitiesSum}, abortEarly: false});
            const nextCampaign = {
                ...data.campaign,
                step: !nextStep || isGreatestStep(nextStep, data.campaign.step) ? data.campaign.step : nextStep,
                isCompleted: data.campaign.isCompleted || editingStep === CAMPAIGN_STEPS.prizes
            };

            if (id) {
                dispatch(modifyCampaign(id, {...data, campaign: nextCampaign, deletedPrizes}, () => {
                    setCampaign(nextCampaign);
                    setFiles({});
                    if (nextStep && !nextCampaign.isCompleted) {
                        history.push(paths.MODIFY_CAMPAIGN.replace(':id', id).replace(':tab?', nextStep));
                    }
                }));
            } else {
                dispatch(createCampaign({...data, campaign: nextCampaign}, (data) => {
                    setCampaign(nextCampaign);
                    if (nextStep && !nextCampaign.isCompleted) {
                        history.push(paths.MODIFY_CAMPAIGN.replace(':id', data.id).replace(':tab?', nextStep));
                    }
                }));
            }
        } catch (err) {
            setErrors(err);
        }
    };

    return (
        <CampaignFormContext.Provider value={{
            campaign,
            setCampaign,
            saveCampaign,
            files,
            setFiles,
            prizes,
            setPrizes,
            deletedPrizes,
            setDeletedPrizes,
            errors,
            setErrors,
            probabilitiesSum,
            availableZones
        }}>
            <CampaignFormLayout/>
        </CampaignFormContext.Provider>
    );
};

export default CampaignCreate;
