import * as yup from 'yup';
import QUANTITY_MODE from "../constants/QuantityMode";
import {CAMPAIGN_STEPS} from "../constants/CampaignSteps";
import {getIntlContext} from "../utils";

yup.setLocale({
    mixed: {
        default: '${path} est invalide.',
        required: '${path} est requis.',
        oneOf: '${path} doit être une des valeurs suivantes: ${values}.',
        notOneOf: '${path} ne doit pas être une des valeurs suivantes: ${values}.',
        defined: '${path} doit être défini.',
    },
    string: {
        length: '${path} doit faire exactement ${length} caractères.',
        min: '${path} doit faire au moins ${min} caractères.',
        max: '${path} doit faire au plus ${max} caractères.',
        matches: '${path} est invalide.',
        email: '${path} doit être une email valide.',
        url: '${path} doit être une URL valide.',
        uuid: '${path} doit être un UUID valide.',
        lowercase: '${path} doit être une chaine en minuscule.',
        uppercase: '${path} doit être une chaine en majuscule.'
    },
    number: {
        min: '${path} doit être supérieur ou égale à ${min}.',
        max: '${path} doit être inférieur ou égale à ${max}.',
        lessThan: '${path} doit être inférieur à ${less}.',
        moreThan: '${path} doit être supérieur à ${more}.',
        notEqual: '${path} ne doit pas être égale à ${notEqual}.',
        positive: '${path} doit être un nombre positif.',
        negative: '${path} doit être un nombre négatif.',
        integer: '${path} doit être un entier.'
    },
    date: {
        min: '${path} doit être une date supérieur à ${min}.',
        max: '${path} doit être une date infirieur à ${max}.'
    },
    array: {
        min: '${path} doit contenir au moins ${min} élément.',
        max: '${path} doit contenir au plus ${max} élément(s).',
    }
});

const requiredWhenEmptyKey = (key) => yup.mixed().when(key, {
    is: val => !val || val.trim().length < 1,
    then: yup.mixed().required(),
    otherwise: yup.mixed().notRequired(),
});

const merge = (...schemas) => {
    const [first, ...rest] = schemas;

    const merged = rest.reduce(
        (mergedSchemas, schema) => mergedSchemas.concat(schema),
        first
    );

    return merged;
}

const firebaseDate = () => yup.object().shape({seconds: yup.number(), nanoseconds: yup.number()});

const {formatMessage} = getIntlContext('fr');

const t = (id, params) => formatMessage({id}, params);

export const generalSchema = yup.object().shape({
    campaign: yup.object().shape({
        ownerId: yup.mixed().required().label(t('GeneralForm.owner')),
        name: yup.string().required().label(t('GeneralForm.name')),
        startDate: firebaseDate().label(t('GeneralForm.startDate')),
        endDate: firebaseDate().label(t('GeneralForm.endDate')),
        maxParticipationsPerUser: yup.number().nullable().min(0).label(t('GeneralForm.maxParticipationsPerUser')),
        intervalParticipationPerUser: yup.number().nullable().min(0).label(t('GeneralForm.intervalParticipationPerUser')),
    }),
});

export const interfaceSchema = yup.object().shape({
    campaign: yup.object().shape({
        zones: yup.number().required().min(1).label(t('InterfaceForm.zones')),
        labels: yup.object().shape({
            color: yup.string().required().label(t('InterfaceForm.color')),
            rgpd: yup.string().required().label(t('InterfaceForm.rgpd')),
            formTitle: yup.string().label(t('InterfaceForm.formTitle')),
            prizesListTitle: yup.string().label(t('InterfaceForm.prizesListTitle')),
            formMetaTitle: yup.string().label(t('InterfaceForm.formMetaTitle')),
            playMetaTitle: yup.string().label(t('InterfaceForm.playMetaTitle')),
            errorMetaTitle: yup.string().label(t('InterfaceForm.errorMetaTitle')),
            errorDefault: yup.string().label(t('InterfaceForm.errorDefault')),
            "campaign/max-games-reached": yup.string().label(t('InterfaceForm.campaign/max-games-reached')),
            "campaign/play-interval": yup.string().label(t('InterfaceForm.campaign/play-interval')),
            prizesMetaTitle: yup.string().label(t('InterfaceForm.prizesMetaTitle')),
            errorTitle: yup.string().label(t('InterfaceForm.errorTitle')),
            noPrize: yup.string().label(t('InterfaceForm.noPrize')),
            submitForm: yup.string().label(t('InterfaceForm.submitForm')),
            formText: yup.string().label(t('InterfaceForm.formText')),
            cgu: yup.string().label(t('InterfaceForm.cgu')),
            companyName: yup.string().label(t('InterfaceForm.companyName')),
            titleFont: yup.string().label(t('InterfaceForm.titleFont')),
            goodLuckTitle: yup.string().label(t('InterfaceForm.goodLuckTitle')),
            waitingTitle: yup.string().label(t('InterfaceForm.waitingTitle')),
        }),
    }),
    files: yup.object().shape({
        backgroundMobilePortrait: requiredWhenEmptyKey('$campaign.files.backgroundMobilePortrait'),
        rouletteUnderHighlight: requiredWhenEmptyKey('$campaign.files.rouletteUnderHighlight'),
        rouletteOnHighlight: requiredWhenEmptyKey('$campaign.files.rouletteOnHighlight'),
        rouletteHighlight: requiredWhenEmptyKey('$campaign.files.rouletteHighlight'),
        roulettePointer: requiredWhenEmptyKey('$campaign.files.roulettePointer'),
        playButton: requiredWhenEmptyKey('$campaign.files.playButton'),
        cgu: requiredWhenEmptyKey('$campaign.files.cgu'),
    }),
});

export const formSchema = yup.object().shape({
    campaign: yup.object().shape({
        isFormEnabled: yup.boolean(),
        formPosition: yup.mixed().oneOf(['start', 'end']).required(),
        fields: yup.mixed().when('isFormEnabled', {
            is: true,
            then: yup.array().of(yup.object().shape({
                id: yup.string().required(),
                type: yup.string().required(),
                required: yup.boolean(),
                maxLength: yup.number(),
                list: yup.string(),
                min: yup.number().min(0),
                max: yup.number(),
            })).min(1, 'Veuillez ajouter au moins un champ.'),
            otherwise: yup.array()
        })
    })
})

export const prizeSchema = yup.object().shape({
    name: yup.string().required().label(t('PrizeForm.name')),
    code: yup.string().required().label(t('PrizeForm.code')),
    image: yup.mixed(),
    probability: yup.number().min(0).max(1).label(t('PrizeForm.probability')),
    quantityMode: yup.mixed().oneOf(Object.values(QUANTITY_MODE)),
    zones: yup.array().of(yup.number()).min(1, 'Veuillez ajouter au moins une zone.'),
    quantity: yup.mixed().when('quantityMode', {
        is: QUANTITY_MODE.prorata,
        then: yup.number().required().min(0).label(t('PrizeForm.quantity'))
    }),
    dailyQuantities: yup.object().transform(function (value) {
        Object.keys(value).forEach(key => value[key] = parseInt(value[key]));
        return value;
    }),
});

export const prizesSchema = yup.object().shape({
    prizes: yup.array().of(prizeSchema).min(1, 'Veuillez ajouter au moins un lot.'),
    probabilitiesSum: yup.number().min(0).max(1).label(t('PrizesForm.probabilitiesSum')),
    availableZones: yup.mixed().when('$probabilitiesSum', {
        is: (val) => val < 1,
        then: yup.array().of(yup.number()).min(1, 'La somme de vos probabilités est inférieur à 1, il est nécessaire de laisser des zones vides (zones perdantes).'),
        otherwise: yup.array().max(0, 'La somme de vos probabilités est égale à 1, vous ne pouvez pas laisser de zones vides (zones perdantes).'),
    }),
    notFullWin: yup.bool().oneOf([false], 'La somme de vos probabilités doit être égale à 1')
});

const CAMPAIGN_SCHEMAS = {
    [CAMPAIGN_STEPS.general]: generalSchema,
    [CAMPAIGN_STEPS.interface]: merge(generalSchema, interfaceSchema),
    [CAMPAIGN_STEPS.fields]: merge(generalSchema, interfaceSchema, formSchema),
    [CAMPAIGN_STEPS.prizes]: merge(generalSchema, interfaceSchema, formSchema, prizesSchema)
};

export default CAMPAIGN_SCHEMAS;
