import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import {useTranslate} from 'hooks';
import {useDispatch, useSelector} from "react-redux";
import {fetchCampaigns} from "../../state/actions/campaigns";
import {FormattedMessage} from "react-intl";
import List from "./List";
import firebase from "../../firebase";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import QRCodeModal from "./QRCodeModal";
import paths from "../Router/paths";
import Button from "react-redux-toastr/lib/Button";
import DrawModal from './DrawModal';

const CampaignConsultation = () => {
    const {t} = useTranslate();
    const {id} = useParams();
    const [campaign, setCampaign] = useState(null);
    const [participations, setParticipations] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [isParticipationsLoading, setParticipationsLoading] = useState(true);
    const [drawOpen, setDrawOpen] = useState(false);
    const isCampaignLoading = useSelector((state) => state.campaigns.loading);
    const loading = !campaign || isCampaignLoading || isParticipationsLoading;

    useAsyncEffect(async () => {
        const campaignDoc = await firebase.firestore().collection('campaigns').doc(id).get();
        setCampaign({id: campaignDoc.id, ...campaignDoc.data()});
    }, [id]);

    useEffect(() => {
        const unsubscribe = firebase
            .firestore()
            .collection('campaigns')
            .doc(id)
            .collection('participations')
            .onSnapshot((snap) => {
                setParticipationsLoading(false);
                const docs = snap.docs
                    .map((doc) => ({id: doc.id, ...doc.data()}))
                    .sort((a, b) => {
                        const aCreatedAt = a.createdAt?.seconds ?? Date.now();
                        const bCreatedAt = b.createdAt?.seconds ?? Date.now();

                        return bCreatedAt - aCreatedAt;
                    });


                setParticipations(docs);
            });

        return () => unsubscribe();
    }, [id]);

    return (
        <>
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <h1 className="title">
                                <FormattedMessage id="ParticipationList.title"/>
                                </h1>
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                <Button onClick={() => setOpen(true)} className="button is-info">
                                    <FormattedMessage id="ParticipationList.qrCodeModal"/>
                                </Button>
                            </div>
                            <div className="level-item">
                              <Button onClick={() => setDrawOpen(true)} className="button is-info">
                                <FormattedMessage id="ParticipationList.draw"/>
                              </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section is-main-section">
                {loading ? (
                    <div className="text-center">
                        <ClipLoader/>
                    </div>
                ) : (
                    <List campaign={campaign} participations={participations}/>
                )}
            </section>
            <QRCodeModal campaignId={campaign?.id} isOpen={isOpen} onClose={() => setOpen(false)}/>
            <DrawModal campaignId={campaign?.id} participations={participations} isOpen={drawOpen} onClose={() => setDrawOpen(false)} />
            </>
    );
};

export default CampaignConsultation;
