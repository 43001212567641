import React, {createContext} from 'react';

const CampaignFormContext = createContext({
    campaign: {},
    files: {},
    prizes: {},
    setCampaign: () => {},
    saveCampaign: () => {},
    setFiles: () => {},
    setPrizes: () => {},
    probabilitiesSum: 0,
    availableZones: [],
});

export default CampaignFormContext;