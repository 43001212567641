import React from 'react';
import FieldForm from "./FieldForm";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

function FieldItem({data, index, editingIndex, setEditing, onSave, onDelete}) {
    const isEditing = editingIndex !== null;

    const handleSubmit = (data) => {
        onSave(data, editingIndex);
        setEditing(null);
    };

    return (
        <li className="box">
            <div className="columns">
                {isEditing ? (
                    <i className="mdi mdi-swap-vertical column is-1"/>
                ) : (
                    <a href="#" className="column is-1">
                        <i className="mdi mdi-swap-vertical"/>
                    </a>
                )}
                <div key={index} className="column is-5">
                    <div className={data.required && 'required'}>{data.name}</div>
                </div>
                <div className="column is-2 is-offset-4 ">
                    <div className="field is-grouped">
                        <p className="control">
                            <button
                                type="button"
                                className="button is-primary"
                                onClick={() => setEditing(index !== editingIndex ? index : null)}
                            >
                                <FormattedMessage id={index === editingIndex ? 'FieldItem.cancelEdit' : 'FieldItem.edit'}/>
                            </button>
                        </p>
                        <p className="control">
                            <button
                                type="button"
                                className="button is-light"
                                onClick={() => onDelete(index)}
                            >
                                <FormattedMessage id="FieldItem.delete"/>
                            </button>
                        </p>
                    </div>
                </div>
            </div>
            {editingIndex === index && (
                <div className="box">
                    <FieldForm
                        data={data}
                        onSubmit={handleSubmit}
                    />
                </div>
            )}
        </li>
    )
}

FieldItem.propTypes = {
    data: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    editingIndex: PropTypes.number,
    setEditing: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

FieldItem.defaultProps = {
    editingIndex: null,
};

export default FieldItem;