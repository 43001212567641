import React from 'react';
import {Route, Switch, BrowserRouter} from 'react-router-dom';

import Login from 'pages/Login';
import Campaigns from 'pages/CampaignList';
import Campaign from 'pages/CampaignForm';
import CampaignConsultation from 'pages/ParticipationList';
import Users from 'pages/Users';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import User from 'pages/User';
import paths from './paths';
import PrivateRoute from './PrivateRoute';
import Showcase from 'pages/Showcase';
import MobileView from 'pages/MobileView';
import Prizes from 'pages/MobileView/Prizes';
import Error from "../MobileView/Error";
import PublicRoute from "./PublicRoute";

const RouterComponent = () => {
    return (
        <BrowserRouter>
            <Switch>
                {/* FRONT */}
                <PublicRoute path={paths.CLIENT_PARTICIPATIONS} component={Prizes}/>
                <PublicRoute path={paths.PUBLIC_ERROR} component={Error}/>
                <PublicRoute path={paths.CAMPAIGN_SHOWCASE} component={Showcase}/>
                <PublicRoute path={paths.CAMPAIGN_CLIENT} component={MobileView}/>

                {/* ADMIN */}
                <PublicRoute exact path={paths.LOGIN} component={Login}/>
                <PublicRoute exact path={paths.RESET_PASSWORD} component={ResetPassword}/>
                <PrivateRoute path={paths.ADD_USER} component={User}/>
                <PrivateRoute path={paths.MODIFY_USER} component={User}/>
                <PrivateRoute path={paths.USERS} component={Users}/>
                <PrivateRoute path={paths.PROFILE} component={Profile}/>
                <PrivateRoute path={paths.ADD_CAMPAIGN} component={Campaign}/>
                <PrivateRoute path={paths.MODIFY_CAMPAIGN} component={Campaign}/>
                <PrivateRoute path={paths.CAMPAIGN_CONSULTATION} component={CampaignConsultation}/>
                <PrivateRoute path={paths.CAMPAIGN_LIST} component={Campaigns}/>
            </Switch>
        </BrowserRouter>
    );
};

export default RouterComponent;
