import React from 'react';
import {useParams} from 'react-router-dom';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from "react-share";
import {format} from 'date-fns';
import ClipLoader from "react-spinners/ClipLoader";
import firebaseDateToDate from '../../utils/firebaseDateToDate';
import useHideSidebar from "../../hooks/useHideSidebar";
import noImage from '../../assets/no-image.png';
import usePublicCampaign from "../../hooks/usePublicCampaign";
import useCachedParticipations from "../../hooks/useCachedParticipations";
import useCampaignTitle from "../../hooks/useCampaignTitle";
import MobileLayout from "../../components/MobileLayout";

const Prizes = () => {
    useHideSidebar();
    const {id} = useParams();
    const campaign = usePublicCampaign(id);
    const [participations] = useCachedParticipations(id);
    const sortedParticipations = participations.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
    
    const hasCompanyUrl= campaign?.labels?.companyButton && campaign?.labels?.companyUrl;
    const facebookShare= campaign?.labels.facebookMessage && campaign?.labels?.facebookShareUrl;
    const twitterShare= campaign?.labels.twitterMessage && campaign?.labels?.twitterShareUrl;

    useCampaignTitle(campaign, 'prizesMetaTitle');

    if (!campaign) {
        return (
            <div className="text-center my-6">
                <ClipLoader/>
            </div>
        );
    }

    return (
        <MobileLayout campaign={campaign} title={campaign.labels.prizesListTitle}>
            <span className="has-text-weight-bold" dangerouslySetInnerHTML={{__html: campaign?.labels?.prizeMessage}}/>
            {hasCompanyUrl && (
                <a
                  type="button"
                  className="button is-medium is-link mb-5 mt-5"
                  href={campaign?.labels?.companyUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                    {campaign?.labels?.companyButton}
                </a>
            )}
            <br/>

            {facebookShare && (
              <FacebookShareButton url={campaign?.labels?.facebookShareUrl} quote={campaign?.labels.facebookMessage}>
                <div className="button is-medium mr-5 mb-5">
                    <div className='icon is-medium mr-2'>
                        <FacebookIcon size={32} />
                    </div>
                  <span>Facebook</span>
                </div>
              </FacebookShareButton>
            )}

            {twitterShare && (
              <TwitterShareButton url={campaign?.labels?.twitterShareUrl} title={campaign?.labels.twitterMessage}>
                <div className="button is-medium mb-5">
                    <div className='icon is-medium mr-2'>
                        <TwitterIcon size={32} />
                    </div>
                  <span>Twitter</span>
                </div>
              </TwitterShareButton>
            )}

            {sortedParticipations.length > 0 ? sortedParticipations.map((participation) => (
                <div key={participation.id} className="card">
                    <div className="media my-5">
                        <div className="media-left">
                            <figure className="image is-48x48 ml-4">
                                <img src={participation.prize.image || noImage} alt={participation.prize.name}/>
                            </figure>
                        </div>
                        <div className="media-content">
                            <p>
                                <b>{participation.prize.name}</b><br/>
                                <b>Code:</b> {participation.code}<br/>
                                {format(firebaseDateToDate(participation.createdAt), 'dd/MM/yyyy HH:mm')}
                            </p>
                        </div>
                    </div>
                </div>)
            ) : (
                <div dangerouslySetInnerHTML={{__html: campaign.labels.noPrize}}/>
            )}
        </MobileLayout>
    );
};

export default Prizes;