import React, {useContext} from 'react';
import {useParams, useHistory, Redirect} from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import GeneralTab from './General';
import InterfaceTab from './Interface';
import FieldsTab from './Fields';
import PrizesTab from './Prizes';
import {useTranslate} from 'hooks';
import {availableSteps, CAMPAIGN_STEPS} from '../../constants/CampaignSteps';
import CampaignFormContext from "./CampaignFormContext";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import paths from "../Router/paths";
import firebase from "firebase";
import useAsyncEffect from "../../hooks/useAsyncEffect";

const CampaignFormLayout = () => {
    const {t} = useTranslate();
    const {id, tab} = useParams();
    const history = useHistory();
    const {campaign, setCampaign, setPrizes, errors} = useContext(CampaignFormContext);

    const {isAdmin, campaigns, allowedCampaigns} = useSelector(state => ({
        campaigns: state.campaigns.data,
        isAdmin: state.auth.userData.isAdmin,
        allowedCampaigns: state.auth.userData.allowedCampaigns
    }));

    useAsyncEffect(async () => {
        if(id){
            const campaignRef = firebase.firestore().collection('campaigns').doc(id);
            const campaignDoc = await campaignRef.get();
            setCampaign({id: campaignDoc.id, ...campaignDoc.data()});

            const prizesSnap = await campaignRef.collection('prizes').get();
            setPrizes(prizesSnap.docs.map((doc) => ({id: doc.id, ...doc.data()})));
        }
    }, [id]);

    const activeTab = Object.values(CAMPAIGN_STEPS).includes(tab) ? tab : CAMPAIGN_STEPS.general;

    const handleTabClick = (tab) => {
        if(id){
            history.push(paths.MODIFY_CAMPAIGN.replace(':id', id).replace(':tab?', tab));
        }
    };

    const TabComponent = (() => {
        switch (activeTab) {
            case CAMPAIGN_STEPS.general:
                return GeneralTab;
            case CAMPAIGN_STEPS.interface:
                return InterfaceTab;
            case CAMPAIGN_STEPS.fields:
                return FieldsTab;
            case CAMPAIGN_STEPS.prizes:
                return PrizesTab;
        }
    })();

    const isLoading = !campaign.id && id;

    const redirect = (!id && !isAdmin && allowedCampaigns === campaigns.length) && <Redirect to={paths.CAMPAIGN_LIST} />;

    return (
        <>
            {redirect}
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <h1 className="title">
                        {t(id ? 'Campaign.editTitle' : 'Campaign.createTitle')}
                    </h1>
                </div>
            </section>
            <div className="notification is-info is-radiusless mb-0">
                <FormattedMessage id="Campaign.creationTutorial" values={{
                    link: (chunks) => <a href="#">{chunks}</a>, // TODO Set link
                }}/>
            </div>
            <section className="section is-main-section">
                {errors?.errors && (
                    <div className="notification is-danger">
                        {errors.errors.map(error => <p>{error}</p>)}
                    </div>
                )}
                {isLoading ? (
                    <div className="text-center">
                        <ClipLoader/>
                    </div>
                ) : (
                    <div className="tile is-ancestor">
                        <div className="tile is-parent">
                            <div className="card tile is-child">
                                <header className="card-header">
                                    <div className="tabs is-medium is-fullwidth w-100">
                                        <ul>
                                            {availableSteps(campaign.step).map((step) => (
                                                <li key={step} className={activeTab === step ? "is-active" : ""}>
                                                    <a onClick={() => handleTabClick(step)}>{t(`CampaignStep.${step}`)}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </header>
                                <div className="card-content">
                                    <TabComponent/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </>
    );
};

export default CampaignFormLayout;
