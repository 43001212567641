const frMessages = {
    "Common.save": "Sauvegarder",
    "Common.submit": "Valider",
    "Common.close": "Fermer",
    "Common.cancel": "Annuler",
    "Common.nextStep": "Étape suivante",
    "Common.ok": "Continuer",
    "Common.pickFile": "Choisir un fichier",

    "ChangePassword.changePassword": "Modifier votre mot de passe",
    "ChangePassword.currentPassword": "Mot de passe actuel",
    "ChangePassword.newPassword": "Nouveau mot de passe",
    "ChangePassword.confirmPassword": "Confirmer le mot de passe",

    "CampaignList.start": "Continuer",
    "CampaignList.startConfirm": "Lancer ma campagne",
    "CampaignList.startMessage": "Êtes-vous sûr de vouloir lancer votre campagne ? Après le lancement, des restrictions d'édition seront appliquées.",
    "CampaignList.user": "Propriétaire",
    "CampaignList.name": "Nom de la campagne",
    "CampaignList.startDate": "Début",
    "CampaignList.endDate": "Fin",
    "CampaignList.isStarted": "Lancé ?",
    "CampaignList.title": "Campagnes",
    "CampaignList.new": "Ajouter une campagne",
    "CampaignList.display": "Campagne",
    "CampaignList.find": "Participations",
    "CampaignList.startCampaign": "Lancer campagne",
    "CampaignList.edit": "Edition",
    "CampaignList.delete": "Supprimer",
    "CampaignList.duplicate": "Dupliquer",
    "CampaignList.qrcodeScan": "Qr code",

    "ParticipationList.code": "Code",
    "ParticipationList.date": "Date",
    "ParticipationList.prize": "Lot",
    "ParticipationList.isGiven": "Remis ?",
    "ParticipationList.clientId": "ID client",
    "ParticipationList.data": "Données",
    "ParticipationList.firstname": "Prénom",
    "ParticipationList.lastname": "Nom",
    "ParticipationList.email": "Adresse email",
    "ParticipationList.tel": "Téléphone",
    "ParticipationList.csv": "Format CSV",
    "ParticipationList.modalTitle": "Données client",
    "ParticipationList.isManual": "Manuelle",

    "CampaignStep.general": "Général",
    "CampaignStep.interface": "Interface",
    "CampaignStep.fields": "Formulaire",
    "CampaignStep.prizes": "Lots",

    "Campaign.createTitle": "Ajouter une campagne",
    "Campaign.editTitle": "Modifier une campagne",
    "Campaign.creationTutorial": "Pour remplir les informations ci-dessous, veuillez vous référer à <link>notre guide PDF</link>.",

    "DrawModal.title": "Tirage au sort",
    "DrawModal.description": "Veuillez ajouter un nouveau lot pour faire un tirage au sort parmi tous les participants.",
    "DrawModal.name": "Nom du lot",
    "DrawModal.code": "Code (interne)",
    "DrawModal.ok": "Lancer tirage au sort",
    "DrawModal.incomplete": "Veuillez remplir tous les champs",
    "DrawModal.hasWonTitle": "Prix :",
    "DrawModal.winner": "Gagnant :",
    "DrawModal.amount": "Quantité des lots",
    "DrawModal.lowerParticipations": "La quantité de participations n'est pas suffisante pour le nombre des lots ajoutés",

    "GeneralForm.name": "Nom de la campagne",
    "GeneralForm.owner": "Propriétaire",
    "GeneralForm.ownerPlaceholder": "Séléctionner un utilisateur",
    "GeneralForm.startDate": "Date de début (00:00)",
    "GeneralForm.endDate": "Date de fin (23:59)",
    "GeneralForm.maxParticipationsPerUser": "Participations maximum (Par client)",
    "GeneralForm.helpMaxParticipationsPerUser": "Laissez zéro (0) si illimité.",
    "GeneralForm.intervalParticipationPerUser": "Intervalle entre deux participations (D'un même client)",
    "GeneralForm.helpIntervalParticipationPerUser": "En minutes; Laissez zéro (0) si illimité.",
    "GeneralForm.addressRequest": "Restriction géographique",
    "GeneralForm.address": "Adresse de l'événement.",
    "GeneralForm.requiredAddress": "L'adresse est requise",
    "GeneralForm.distance": "Distance maximum",
    "GeneralForm.helpDistance": "Distance en mètres",
    "GeneralForm.addressPlaceholder": "Saisissez...",
    "GeneralForm.loading": "Recherche en cours",
    "GeneralForm.noOption": "Aucune option",

    "InterfaceForm.color": "Couleur primaire",
    "InterfaceForm.backgroundShowcasePortrait": "Background vitrine (portrait)",
    "InterfaceForm.backgroundShowcaseLandscape": "Background vitrine (paysage)",
    "InterfaceForm.backgroundMobilePortrait": "Background mobile",
    "InterfaceForm.rouletteUnderHighlight": "Arrière plan roulette",
    "InterfaceForm.rouletteOnHighlight": "Zone surbrillance roulette",
    "InterfaceForm.rouletteHighlight": "Premier plan roulette",
    "InterfaceForm.roulettePointer": "Curseur roulette",
    "InterfaceForm.playButton": "Bouton \"Jouer\" interface mobile",
    "InterfaceForm.zones": "Nombre de zones (roue)",
    "InterfaceForm.cgu": "Conditions générales d'utilisation et règles du jeu",
    "InterfaceForm.rgpd": "Message de protection des données personnelles (Case RGPD) et règles du jeu",
    "InterfaceForm.logo": "Logo de l'entreprise",
    "InterfaceForm.formText": "Message avant le formulaire",
    "InterfaceForm.formTitle": "Titre du formulaire",
    "InterfaceForm.prizesListTitle": "Titre liste des lots",
    "InterfaceForm.formMetaTitle": "Meta titre formulaire",
    "InterfaceForm.playMetaTitle": "Meta titre jeu",
    "InterfaceForm.errorMetaTitle": "Meta titre erreur",
    "InterfaceForm.errorDefault": "Message d'erreur général",
    "InterfaceForm.campaign/max-games-reached": "Nombre de participations maximum atteint",
    "InterfaceForm.campaign/play-interval": "Délais entre deux participations insuffisant",
    "InterfaceForm.prizesMetaTitle": "Meta titre liste des lots",
    "InterfaceForm.errorTitle": "Titre page erreur",
    "InterfaceForm.noPrize": "Aucun lots",
    "InterfaceForm.submitForm": "Bouton validation formulaire",
    "InterfaceForm.companyName": "Nom de l'entreprise",
    "InterfaceForm.titleFont": "Police du titre (Google Font)",
    "InterfaceForm.goodLuckTitle": "Texte jeu \"bonne chance\"",
    "InterfaceForm.waitingTitle": "Texte jeu attente",
    "InterfaceForm.prizeMessage": "Message à afficher sur la liste des lots du joueur",
    "InterfaceForm.companyUrl": "Site internet de l'entreprise",
    "InterfaceForm.companyButton": "Bouton \"Visiter le site de l'entreprise\"",
    "InterfaceForm.facebookMessage": "Message publication sur facebook",
    "InterfaceForm.facebookShareUrl": "URL à partager sur facebook",
    "InterfaceForm.twitterMessage": "Message publication sur twitter",
    "InterfaceForm.twitterShareUrl": "URL à partager sur twitter",
    "InterfaceForm.lostRedirect": "Adresse à rediriger si le joueur ne gagne rien",
    "InterfaceForm.sendEmailRequest": "Envoyer un e-mail de participation à chaque joueur",
    "InterfaceForm.winnerSubject": "Sujet e-mail gagnant",
    "InterfaceForm.winnerEmail": "E-mail gagnant",
    "InterfaceForm.loserSubject": "Sujet e-mail perdant",
    "InterfaceForm.loserEmail": "E-mail perdant",

    "FieldsForm.isFormEnabled": "Formulaire activé ?",
    "FieldsForm.startPosition": "Début (recommandé)",
    "FieldsForm.endPosition": "Fin",
    "FieldsForm.addTitle": "Ajouter un champ",

    "FieldForm.name": "Nom",
    "FieldForm.type": "Type de champ",
    "FieldForm.maxLength": "Nombre de caractères maximum",
    "FieldForm.min": "Valeur minimum",
    "FieldForm.max": "Valeur maximum",
    "FieldForm.list": "Valeurs",
    "FieldForm.helpList": "Séparées par un point virgule (;).",
    "FieldForm.required": "Requis ?",
    "FieldForm.add": "Valider",

    "FieldTypes.text": "Texte",
    "FieldTypes.firstname": "Prénom",
    "FieldTypes.lastname": "Nom",
    "FieldTypes.email": "Adresse email",
    "FieldTypes.number": "Nombre",
    "FieldTypes.tel": "Téléphone",
    "FieldTypes.select": "Liste déroulante",
    "FieldTypes.radio": "Options",
    "FieldTypes.checkbox": "Case à cocher",

    "FieldItem.cancelEdit": "Annuler",
    "FieldItem.edit": "Éditer",
    "FieldItem.delete": "Supprimer",

    "Participation.farDistance": "Vous êtes trop loin...",
    "Participation.description": "Vous ne pouvez pas participer si vous n'êtes pas sur le lieu de l'évènement.",
    "Participation.geolocation": "Nous ne parvenons pas à vous localiser...",
    "Participation.geolocationError": "Vous n'avez pas autorisé la géolocalisation ou votre navigateur ne la supporte pas.",
    "Participation.zeroPrizes": "La campagne est terminée.",
    "Participation.zeroPrizesDescription": "Merci pour votre compréhension.",

    "PrizesForm.excessiveProbabilities": "La somme de vos probabilités ne doit pas être supérieure à 1, elle vaut actuellement {probabilitiesSum, number}. Veuillez corriger les probabilités.",
    "PrizesForm.probabilitiesSumMessage": "La somme de vos probabilités est de {probabilitiesSum, number}.",
    "PrizesForm.loseRate": "Ce qui signifie que {loseRate, number}% des participations peuvent être perdantes.",
    "PrizesForm.addPrize": "Ajouter un lot",
    "PrizesForm.probabilitiesSum": "La somme de vos probabilités",
    "PrizesForm.image": "Image du lot",
    "PrizesForm.name": "Nom du lot",
    "PrizesForm.quantity": "Quantité",
    "PrizesForm.probability": "Probabilité",
    "PrizesForm.actions": "Actions",
    "PrizesForm.delete": "Supprimer",
    "PrizesForm.confirm": "Confirmer l'action",
    "PrizesForm.permDelete": "Cela supprimera définitivement les données. L'action ne peut pas être annulée.",
    "PrizesForm.cancel": "Annuler",
    "PrizesForm.fullWin": "100 % gagnant",
    "PrizesForm.fullWinProbabilitiesSum": "Option 100 % gagnant choisi, La somme de vos probabilités doit être égal à 1",

    "PrizeForm.editTitle": "Modifier {name}",
    "PrizeForm.addTitle": "Ajouter un lot",
    "PrizeForm.name": "Nom du lot",
    "PrizeForm.code": "Code (interne)",
    "PrizeForm.image": "Image",
    "PrizeForm.probability": "Probabilité",
    "PrizeForm.positions": "Zones sur la roue",
    "PrizeForm.quantity": "Quantité",
    "PrizeForm.quantityMode": "Mode de quantité",
    "PrizeForm.dailyQuantity": "Journalière",
    "PrizeForm.prorata": "Prorata",
    "PrizeForm.globalQuantity": "Quantité globale",
    "PrizeForm.availableQuantity": "{count} disponible(s)",
    "PrizeForm.dailyQuantitiesButton": "Quantités journalières",
    "PrizeForm.save": "Sauvegarder",
    "PrizeForm.close": "Annuler",

    "DailyQuantities.title": "Quantités journalières",
    "DailyQuantities.availableQuantity": "{count} disponible(s)",

    "PrizeRow.dailyQuantity": "Quantité journalière (voir dans l'édition)",

    "ParticipationList.title": "Liste des participations",
    "ParticipationList.draw": "Tirage au sort",
    "ParticipationList.qrCodeModal": "QR Code client",
    "QRCodeModal.title": "Accéder à mes lots",

    "Errors.title": "Une erreur est survenue.",
    "Errors.errorMetaTitle": "Erreur - Happy Wheel",
    "Errors.default": "<center>Une erreur est survenue, veuillez réessayer plus tard.</center>",
    "Errors.campaign/not-found": "<center>Campagne introuvable. Veuillez vérifier l'URL.</center>",
    "Errors.campaign/not-started": "<center>La campagne n'est pas en cours. Veuillez réessayer une fois la campagne lancée.</center>",
    "Errors.campaign/max-games-reached": "<center>Vous avez atteint le nombre de participations maximum pour cette campagne.</center>",
    "Errors.campaign/play-interval": "<center>Veuillez patienter avant de pouvoir participer à nouveau à cette campagne.</center>",

    "Login.login": "S'identifier",
    "Login.setNewPassword": "Définissez votre nouveau mot de passe",
    "Login.email": "Adresse email",
    "Login.password": "Mot de passe",
    "Login.setPassword": "Définir le mot de passe",
    "Login.forgotPassword": "Mot de passe oublié?",

    "Aside.campaign": "Campagnes",
    "Aside.users": "Utilisateurs",
    "NavBar.profile": "Profil",
    "NavBar.logOut": "Déconnexion",
    "Profile.profile": "Profil",

    "ResetPassword.recovery": "Récupération de mot de passe",
    "ResetPassword.recoverEmail": "Nous vous avons envoyé un email à {mail} afin que vous puissiez réinitialiser votre mot de passe.",
    "ResetPassword.email": "Adresse email",
    "ResetPassword.emailRegistration": "Adresse email",
    "ResetPassword.resetLink": "Envoyer le lien de réinitialisation",
    "ResetPassword.back": "Retour",

    "showcase.zeroPrizes": "Campagne terminée, tous les lots ont été distribués. *",

    "Users.name": "Nom",
    "Users.email": "Email",
    "Users.admin": "Admin",
    "Users.startDate": "Date de début",
    "Users.endDate": "Date de fin",
    "Users.delete": "Supprimer",
    "Users.confirm": "Confirmer l'action",
    "Users.permDelete": "Cela supprimera définitivement les données. L'action ne peut pas être annulée.",
    "Users.cancel": "Annuler",
    "Users.users": "Utilisateurs",
    "Users.newUser": "Ajouter un utilisateur",
    "Users.search": "Chercher:",
    "Users.allowedCampaigns": "Nombre de campagnes",
    "Users.permDuplicate": "Attention ! Dupliquer ajoutera une campagne en plus, êtes-vous sûr ? ",
    "Users.duplicate": "Dupliquer",

    "User.editUser": "Modifier l'utilisateur",
    "User.newUser": "Ajouter un utilisateur",

    "UserForm.invalidEmail": "Email invalide",
    "UserForm.userInfo": "informations de l'utilisateur",
    "UserForm.email": "Email",
    "UserForm.name": "Nom",
    "UserForm.admin": "Admin",
    "UserForm.startDate": "Date de début",
    "UserForm.endDate": "Date de fin",
    "UserForm.submit": "Sauvegarder",
    "UserForm.goBack": "Retour",
    "UserForm.allowedCampaigns": "Nombre de campagnes",

    "Table.perPage": " par page",

    "auth/email-already-exists": "Email déjà utilisé",
    "auth/invalid-email": "Email invalide",
    "auth/wrong-password": "Informations de connexion invalides",
    "auth/user-disabled": "Utilisateur désactivé",
    "auth/too-many-requests": "Trop de tentatives effectuées, réessayez plus tard",
    "auth/expired-action-code": "Le lien d'invitation a expiré, contactez votre administrateur",
    "auth/invalid-action-code": "Le lien d'invitation a expiré, contactez votre administrateur",
    "storage/quota-exceeded": "Erreur de serveur interne, contactez votre administrateur",
    "storage/unauthenticated": "Non authentifié, veuillez vous authentifier et réessayer",
    "storage/unauthorized": "Vous n'êtes pas autorisé à effectuer cette action",
    "auth/account-exists-with-different-credential": "Email sur une autre méthode de connexion",
    "auth/popup-blocked": "Pop up bloqué par le navigateur, veuillez activer les pop-ups pour cette page",
    "auth/popup-closed-by-user": "L'opération a été annulée, la fenêtre contextuelle (pop-up) a été fermée",
    "auth/cancelled-popup-request": "Trop de pop-ups, un seul pop-up est autorisé",
    "storage/object-not-found": "Échec du chargement de l'image",

    "utils.default": "Erreur inconnue, contactez votre administrateur",
    "utils.safePassword": "Mot de passe sécurisé",
    "utils.unsafePassword": "Mot de passe non sécurisé",
    "utils.invalidEmail": "Email invalide",
};

export default frMessages;
