import React, {useContext} from 'react';
import {useSelector} from "react-redux";
import CampaignFormContext from "./CampaignFormContext";
import {useTranslate} from "../../hooks";
import PropTypes from 'prop-types';

function FormFooter({onSubmit}) {
    const {t} = useTranslate();
    const {campaign} = useContext(CampaignFormContext);
    const loading = useSelector(state => state.campaigns.loading);

    return (
        <>
            <hr/>
            <div className="level-right">
                <button
                    type={onSubmit ? 'button' : 'submit'}
                    onClick={onSubmit}
                    className={`button level-item is-primary ${loading && 'is-loading'}`}
                >
                    <span>{campaign.isCompleted ? t('Common.save') : t('Common.nextStep')}</span>
                </button>
            </div>
        </>
    )
}

FormFooter.propTypes = {
  onSubmit: PropTypes.func,
};

FormFooter.defaultProps = {
  onSubmit: undefined,
};

export default FormFooter;