import React, {useMemo, useState} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

const FileInput = ({label, onChange, value, required, ...props}) => {
    const [file, setFile] = useState(null);
    const preview = useMemo(() => (file && URL.createObjectURL(file)) || value, [file, value]);
    const isImage = useMemo(() => {
        const fileValue = value instanceof File ? value : file;

        if (value instanceof File) {
            setFile(value);
        }

        if (fileValue) {
            return ['image/png', 'image/jpeg', 'image/webp', 'image/svg+xml', 'image/svg'].includes(fileValue.type);
        }

        if (value) {
            const extension = value.split('?').shift().split('.').pop();
            return ['png', 'jpg', 'jpeg', 'webp', 'svg'].includes(extension);
        }

        return false;
    }, [file, value]);

    const handleChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
        onChange(event);
    };

    return (
        <div className="field">
            <label className={`label ${required && 'required'}`}>
                {label}
            </label>
            <div className="field">
                <div className="file has-name">
                    <label className="file-label">
                        <input
                            className="file-input"
                            type="file"
                            onChange={handleChange}
                            required={required}
                            {...props}
                        />
                        <div className="file-cta">
                            <span className="file-icon"><i className="mdi mdi-upload"/></span>
                            <span className="file-label">
                                <FormattedMessage id="Common.pickFile"/>
                            </span>
                        </div>
                        <span className="file-name">{file?.name || 'Aucun fichier'}</span>
                    </label>
                </div>
                {preview && (
                    <>
                        {isImage ? (
                            <div className="mt-5 image is-aligned-center ">
                                <img
                                    style={{maxHeight: '128px', width: 'auto', maxWidth: '100%'}}
                                    src={preview}
                                    alt={`Aperçu ${label}`}
                                />
                            </div>
                        ) : (
                            <div className="mt-1">
                                <a href={preview} target="_blank">Ouvrir le fichier</a>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

FileInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
};

FileInput.defaultProps = {
    value: null,
    required: false,
};

export default FileInput;
