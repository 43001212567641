const IMAGE_INPUT = {accept: "image/jpeg, image/png", required: true};
const FILE_INPUTS = [
    {key: 'backgroundShowcasePortrait', ...IMAGE_INPUT, required: false},
    {key: 'backgroundShowcaseLandscape', ...IMAGE_INPUT, required: false},
    {key: 'backgroundMobilePortrait', ...IMAGE_INPUT},
    {key: 'rouletteUnderHighlight', ...IMAGE_INPUT},
    {key: 'rouletteOnHighlight', ...IMAGE_INPUT},
    {key: 'rouletteHighlight', ...IMAGE_INPUT},
    {key: 'roulettePointer', ...IMAGE_INPUT},
    {key: 'playButton', ...IMAGE_INPUT},
    {key: 'logo', ...IMAGE_INPUT, required: false},
    {key: 'cgu', accept: 'application/pdf', required: true}
];

export default FILE_INPUTS;