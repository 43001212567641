import React, { useState } from 'react';
import { useTranslate } from '../../hooks';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { FormattedMessage } from 'react-intl';
import firebase from '../../firebase';
import slugify from '../../utils/slugify';

const DrawModal = ({campaignId, isOpen, onClose, participations}) => {
  const {t} = useTranslate();

  const [errors, setErrors] = useState(null);
  const [isLoading, setLoading]= useState(false);
  const [localPrize, setLocalPrize]= useState({name: undefined, code: undefined, amount: 1});
  const [winners, setWinners]= useState(undefined)

  const handleChange= (type, value) => {
    if (type === 'amount') {
      const numberExp = /[0-9]|\./;
      if (numberExp.test(value)) {
        setLocalPrize(prevState => ( {...prevState, [`${type}`]: value}) )
      }
    }else {
      setLocalPrize(prevState => ( {...prevState, [`${type}`]: value}) )
    }
  }

  const handleGame = async () => {
    if (localPrize.name && localPrize.code !== undefined) {
      try {
        setLoading(true);
        setErrors(null);

        const createDraw = firebase.functions().httpsCallable('httpsCreateDraw');
        const participationData= await createDraw({participations, campaignId, data: localPrize});

        setWinners(participationData.data);
      } catch (err) {
        setErrors({errors: [t(err.message)]});
      } finally {
        setLoading(false)
      }
    }else {
      setErrors({errors: [t('DrawModal.incomplete')]})
    }
  };

  const handleClose= () => {
    setWinners(undefined);
    setLocalPrize({name: undefined, code: undefined, amount: 1});
    setErrors(null);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t('DrawModal.title')}
    >
      <section className="modal-card-body">
        <div className="card-content">

          {errors?.errors && (
            <div className="notification is-danger">
              {errors.errors.map(error => <p key={error}>{error}</p>)}
            </div>
          )}
          {winners ?
            winners.map(winner => (
              <div className={'notification'}>
                <p className={'mb-2 title is-title-3'}>{t('DrawModal.hasWonTitle')}</p>
                <div className={'mt-2'}>
                  <b className="is-inline-block mr-1">{winner.prize?.name} ({winner.prize?.code}):</b>
                </div>
                <p className={'mt-2 mb-2 title is-title-4'}>{t('DrawModal.winner')}</p>
                {winner?.data.map((element) => (
                  <div key={slugify(element.name)}>
                    <b className="is-inline-block mr-1">{element.name}:</b>
                    {element.value}
                  </div>
                ))}
                <div >
                  <b className="is-inline-block mr-1">Code:</b>
                  <span className="tag is-success is-light is-medium">{winner?.code}</span>
                </div>
            </div>
            )) : (
              <div className='notification '>
                <p>{t('DrawModal.description')}</p>
              </div>
          )}

          {!winners && <>
            <div className='field'>
              <label htmlFor='name' className='label required'>
                <FormattedMessage id='DrawModal.name' />
              </label>
              <div className='control'>
                <input
                  id='name'
                  className='input'
                  type='text'
                  required
                  name='name'
                  value={localPrize.name}
                  onChange={e => handleChange('name', e.target.value)}
                />
              </div>
            </div>

            <div className='field'>
              <label htmlFor='code' className='label required'>
                <FormattedMessage id='DrawModal.code' />
              </label>
              <div className='control'>
                <input
                  id='code'
                  type='text'
                  className='input'
                  required
                  name='code'
                  value={localPrize.code}
                  onChange={e => handleChange('code', e.target.value)}
                />
              </div>
            </div>

            <div className='field'>
              <label htmlFor='amount' className='label required'>
                <FormattedMessage id='DrawModal.amount' />
              </label>
              <div className='control'>
                <input
                  id='amount'
                  type='number'
                  className='input'
                  required
                  name='amount'
                  value={localPrize.amount}
                  min="1"
                  onChange={e => handleChange('amount', e.target.value)}
                />
              </div>
            </div>
          </>}

        </div>
      </section>
      <footer className="modal-card-foot">
        {!winners && <button className={`button is-primary ${isLoading ? 'is-loading' : undefined}`} onClick={handleGame}>
          <FormattedMessage id='DrawModal.ok' />
        </button>}
        <button className="button" onClick={handleClose}>
          <FormattedMessage id="Common.close"/>
        </button>
      </footer>
    </Modal>
  );
};

DrawModal.propTypes = {
  campaignId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DrawModal;