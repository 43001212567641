import React from 'react';
import classes from './index.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function MobileLayout({campaign, title, children}) {
    return (
        <div className="card mx-2 mt-2">
            <header className={classNames('card-header', classes.header)}>
                {campaign?.files?.logo && (
                    <div className="text-center mb-4">
                        <img src={campaign.files.logo} alt={campaign.labels.companyName} className={classes.logo}/>
                    </div>
                )}
                {campaign && !campaign?.files?.logo && (
                    <h1 className="card-header-title title is-1 mb-4 is-centered is-uppercase">{campaign.labels.companyName}</h1>
                )}
                <h3 className="card-header-title title is-3 is-centered pb-0">{title}</h3>
            </header>
            <div className="card-content">
                {children}
            </div>
            <div className="card-footer">
                <p className="card-footer-item">
                    <span>&copy; Happy Wheel</span>
                </p>
                {campaign && (
                    <p className="card-footer-item">
                        <a target="_blank" href={campaign.files.cgu}>{campaign.labels.cgu}</a>
                    </p>
                )}
            </div>
        </div>
    );
}

MobileLayout.propTypes = {
    campaign: PropTypes.shape({
        files: PropTypes.shape({
            logo: PropTypes.string,
            cgu: PropTypes.string,
        }),
        labels: {
            cgu: PropTypes.string,
            companyName: PropTypes.string,
        },
    }),
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

MobileLayout.defaultProps = {
    campaign: null,
};

export default MobileLayout;