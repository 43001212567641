import { useIntl } from 'react-intl';

const useChangeHandler = setState => {
  const onChangeHandler = event => {
    const { target } = event;

    let value = target.value;
    if(target.type === 'file'){
      value = target.files[0];
    } else if(target.type === 'checkbox') {
      value = target.checked;
    }

    setState(prevState => ({ ...prevState, [target.name]: value }));
  };

  return onChangeHandler;
};

const useFieldChangeHandler = (setState, index=0, campaign) => {        
  const onChangeHandler = event => {
    const { target } = event;
    const {value} = target;
    const { name } = target;
    if (name === 'objectKey') {            
      const newLabel= {objectKey: value, objectValue: campaign.labels[index].objectValue };
      const labels= campaign.labels.map((label, i) => i === index ? newLabel: label);
      setState(prevState => ({ ...prevState, labels }));
        
    }else if(name === 'objectValue') {
      const newLabel= {objectKey: campaign.labels[index].objectKey, objectValue: value };
      const labels= campaign.labels.map((label, i) => i === index ? newLabel: label);
      setState(prevState => ({ ...prevState, labels }));
    }
    else {
      setState(prevState => ({ ...prevState, [`${name}`]: value }));
    }
  };

  return onChangeHandler;
};

const useFormatMessage = (
  id,
  values = {},
  defaultMessage = '',
  description = ''
) => {
  const intl = useIntl();
  return intl.formatMessage({ id, defaultMessage, description }, values);
};

const useFormatDate = (value, options = {}) => {
  const intl = useIntl();
  return value && intl.formatDate(value, options);
};

const useTranslate = () => {
  const {formatMessage, formatDate} = useIntl();
  const t = (id, values, defaultMessage, description) =>  formatMessage({ id, defaultMessage, description }, values);
  return {t, formatDate};
}

export { useChangeHandler, useFormatMessage, useFormatDate, useFieldChangeHandler, useTranslate };
