import React, {useState, useEffect, useRef, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import classes from './index.module.scss';
import QRCode from 'qrcode.react';
import Roulette from '../../components/Roulette/Roulette';
import useDimensions from 'hooks/useDimensions';
import ClipLoader from 'react-spinners/ClipLoader';
import firebase from "../../firebase";
import Confetti from 'react-confetti';
import classNames from 'classnames';
import usePublicCampaign from "../../hooks/usePublicCampaign";
import paths from "../Router/paths";
import {useTranslate} from "../../hooks";

const Showcase = () => {
    const {id} = useParams();
    const {width, height} = useDimensions();
    const campaign = usePublicCampaign(id, false);
    const {t} =useTranslate();

    const isLandscape = window.matchMedia("(orientation:landscape)").matches;
    const backgroundUrl = isLandscape ? campaign?.files?.backgroundShowcaseLandscape : campaign?.files?.backgroundShowcasePortrait;
    const urlClient = `https://${window.location.hostname}${paths.CAMPAIGN_CLIENT.replace(':id', id)}`;
    const titleSize = isLandscape ? height * (5 / 100) : width * (5 / 100);
    const customFont = campaign?.labels?.titleFont?.trim();
    const hasCustomFont = customFont && customFont.length > 0;
    const titleFont = hasCustomFont ? `${customFont}, Nunito` : 'Nunito';

    const [participation, setParticipation] = useState();
    const participationId = useRef(null);
    const [hasWon, setWon] = useState(false);
    const [hasLose, setLose] = useState(false);

    const prize = participation?.prize;
    const targetZone = prize?.zones ? prize.zones[0] : campaign?.emptyZones[0];

    const participationData = participation?.data;
    const firstname = participationData?.find(({id}) => id === 'firstname')?.value;
    const goodLuckMessage = campaign?.labels?.goodLuckTitle?.replace('[prenom]', firstname);

    useEffect(() => {
        let firstCall = true;
        const participationsRef = firebase.database().ref(`campaigns/${id}`);
        participationsRef.on('child_added', snapshot => {
            if (firstCall) {
                return;
            }

            const data = snapshot.val();
            if (data && !participationId.current) {
                participationId.current = data.id;
                setParticipation(data);
            }
        });

        firebase.database().ref('invites').once('value', () => {
            firstCall = false;
        });

        return () => participationsRef.off();
    }, []);

    useEffect(() => {
        if (hasCustomFont) {
            // eslint-disable-next-line no-unused-expressions
            window?.WebFont?.load({
                google: {
                    families: [customFont]
                }
            });
        }
    }, [customFont, hasCustomFont]);

    const reset = () => {
        setWon(false);
        setLose(false);
        setParticipation(null);
        participationId.current = null;
    };

    useEffect(() => {
        if (hasLose) {
            setTimeout(reset, 5000);
        }
    }, [hasLose]);

    const handleWheelComplete = () => {
        if (prize) {
            setWon(true);
        } else {
            setLose(true);
        }
    };

    const handleConfettiComplete = () => {
        setTimeout(reset, 1000);
    };

    if (!campaign) {
        return (
            <div className={classNames('text-center my-6', classes.root)}>
                <ClipLoader/>
            </div>
        );
    }

    return (
        <div className={classes.root} style={{backgroundImage: `url(${backgroundUrl})`}}>
            {hasWon && (
                <Confetti
                    width={width}
                    height={height}
                    recycle={false}
                    numberOfPieces={500}
                    tweenDuration={6000}
                    onConfettiComplete={handleConfettiComplete}
                    run={true}
                />
            )}

            <div className={classes.title} style={{
                fontFamily: titleFont,
                color: campaign.labels.color,
                fontSize: `${titleSize}px`,
                lineHeight: `${titleSize}px`
            }}>
                {participation ? goodLuckMessage : campaign.labels.waitingTitle}
            </div>

            <div className={classes.gamebox}>
                <Roulette
                    rouletteBackground={campaign.files.rouletteUnderHighlight}
                    rouletteOverlay={campaign.files.rouletteHighlight}
                    highlightImage={campaign.files.rouletteOnHighlight}
                    pointerImage={campaign.files.roulettePointer}
                    nbZones={campaign.zones}
                    targetZone={participation ? targetZone : null}
                    onComplete={handleWheelComplete}
                />
            </div>

            <div className={classes.qrCode}>
                <QRCode
                    value={urlClient}
                    renderAs="svg"
                    level="H"
                    fgColor={campaign.labels.color}
                    bgColor='rgba(255,255,255,0)'
                />
            </div>
            {campaign.zeroPrizes && (
                <div className="tile is-parent">
                    <article className="tile is-child notification is-danger">
                        <p className="subtitle">{t('showcase.zeroPrizes')}</p>
                    </article>
                </div>
            )}
        </div>
    );
};

export default Showcase;
