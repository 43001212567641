import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import useDimensions from 'hooks/useDimensions';
import Confetti from 'react-confetti';
import {v4 as uuidv4} from "uuid";
import Roulette from '../../components/Roulette/Roulette';
import classes from './index.module.scss';
import ClientForm from './ClientForm';
import firebase from "../../firebase";
import paths from "../Router/paths";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import FORM_POSITION from "../../constants/FormPosition";
import useDeviceToken from "../../hooks/useDeviceToken";
import usePublicCampaign from "../../hooks/usePublicCampaign";
import useCachedParticipations from "../../hooks/useCachedParticipations";
import useCampaignTitle from "../../hooks/useCampaignTitle";
import MobileLayout from "../../components/MobileLayout";
import {useTranslate} from "../../hooks";

const MobileView = () => {
    const {t} = useTranslate();
    const history = useHistory();
    const deviceToken = useDeviceToken();
    const {width, height} = useDimensions();

    const {id} = useParams();
    const campaign = usePublicCampaign(id);

    const [, addParticipation] = useCachedParticipations(id);
    const [participationId, setParticipationId] = useState(null);
    const [participation, setParticipation] = useState(null);
    const [formData, setFormData] = useState([]);
    const [isFormShowing, setFormShowing] = useState(false);
    const [isFormLoading, setFormLoading] = useState(false);
    const [isPlayLoading, setPlayLoading] = useState(false);
    const [hasWon, setWon] = useState(false);
    const [status, setStatus] = useState(null);
    const [disabledByDistance, setDisabledByDistance] = useState(null);
    const [zeroPrizes, setZeroPrizes] = useState(false);

    const prize = participation?.prize;
    const targetZone = prize?.zones ? prize.zones[0] : campaign?.emptyZones[0];

    useCampaignTitle(campaign, isFormShowing ? 'formMetaTitle' : 'playMetaTitle');

    const calculateDistance = (lattitude1, longittude1, lattitude2, longittude2) =>
    {

        const toRadian = n => (n * Math.PI) / 180;

        const lat2 = lattitude2;
        const lon2 = longittude2;
        const lat1 = lattitude1;
        const lon1 = longittude1;

        const R = 6371;  // km
        const x1 = lat2 - lat1;
        const dLat = toRadian(x1);
        const x2 = lon2 - lon1;
        const dLon = toRadian(x2);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadian(lat1)) * Math.cos(toRadian(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;

        return Math.trunc(d * 1000 );
    };

    useEffect(() => {
        if (!participationId){
            setParticipationId(uuidv4());
        } else {
            const participationRef = firebase.database().ref(`campaigns/${id}/${participationId}`);
            participationRef.on('value', (snapshot) => {
                const data = snapshot.val();

                if (data){
                    setParticipation(snapshot.val());
                }
            });

            return () => participationRef.off();
        }
    }, [participationId, id]);

    useAsyncEffect(async () => {
        if (campaign && campaign.isFormEnabled && campaign.formPosition === FORM_POSITION.start) {
            setFormShowing(true);
        }
        if (campaign && campaign.isAddressEnabled) {
            if (!navigator.geolocation) {
                setStatus(t('Participation.geolocation'));
            } else {
                navigator.geolocation.getCurrentPosition((position) => {
                    setStatus(null);
                    const calculatedDistance = calculateDistance(position.coords.latitude, position.coords.longitude, campaign.address.latitude, campaign.address.longitude);

                    if (calculatedDistance > parseInt(campaign.participationDistance)) {
                        setDisabledByDistance(true);
                    }
                }, () => {
                    setStatus(t('Participation.geolocationError'));
                });
            }
        }
        if (campaign && campaign.zeroPrizes) {
            setZeroPrizes(true);
        }
    }, [campaign]);

    const redirectError = (err) => {
        history.push(`${paths.PUBLIC_ERROR}?error=${encodeURIComponent(err.message)}&id=${id}`);
    };

    const redirectLost= () => {
        if(!!campaign?.labels?.lostRedirect && campaign?.labels?.lostRedirect?.length > 0){
            window.location.href = `${campaign?.labels?.lostRedirect}`;
        }else {
            window.alert('Vous avez perdu, retentez votre chance plus tard.')
        }
    };

    const handleSubmit = async (data) => {
        if (participation) {
            setFormLoading(true);

            try {
                const updateParticipationData = firebase.functions().httpsCallable('httpsUpdateParticipationData');
                await updateParticipationData({campaignId: id, participationId: participation.id, data, deviceToken});
                history.push(paths.CLIENT_PARTICIPATIONS.replace(':id', id));
            } catch (err) {
                return redirectError(err);
            }
        }

        setFormData(data);
        setFormShowing(false);
    };

    const handleGame = async () => {
        try {
            setPlayLoading(true);
            const createParticipation = firebase.functions().httpsCallable('httpsCreateParticipation');
            const {data} = await createParticipation({participationId, campaignId: id, data: formData, deviceToken});
             if (data.prize) {
                addParticipation(data);
            }else {
                 setTimeout(()=> {
                    redirectLost();
                }, 6000);
            }
        } catch (err) {
            return redirectError(err);
        }
    };

    const handleWheelComplete = () => setWon(!!prize);

    const handleConfettiComplete = () => {
        if (campaign.isFormEnabled && campaign.formPosition === FORM_POSITION.end) {
            setFormShowing(true);
        } else {
            history.push(paths.CLIENT_PARTICIPATIONS.replace(':id', id));
        }
    };

    if (!campaign) {
        return (
            <div className="text-center my-6">
                <ClipLoader/>
            </div>
        );
    }

    const backgroundWidth = width <= 768 ? width * 1920 / 768 : 1920;
    const gameWidth = width <= 768 ? width : 768;
    const gameHeight = gameWidth * 1024 / 768;
    const wheelSize = gameWidth * 520 / 768;
    const wheelTop = gameWidth * 250 / 768;
    const buttonAreaHeight = gameWidth * 204 / 768;
    const footerHeight = gameWidth * 50 / 768;
    const footerFontSize = gameWidth * 17 / 768;
    // Width of visible area outside game area
    const sMargin = width <= 768 ? 0 : (width - 768) / 2;
    // Calculate left position based on invisible area, outside screen
    const backgroundLeftOffset = (gameWidth - backgroundWidth) / 2 + sMargin;

    if (disabledByDistance || status || zeroPrizes) {
        const title = zeroPrizes ? t('Participation.zeroPrizes') : t('Participation.farDistance');
        const subtitle = zeroPrizes ? t('Participation.zeroPrizesDescription'): t('Participation.description');
        return(
            <section className='hero is-fullheight '>
                <div className="hero-body">
                    <div >
                        {status ? (
                            <p className="title has-text-danger">{status}</p>)
                        : (
                            <>
                                <p className="title has-text-danger">
                                    {title}
                                </p>
                                <p className="subtitle">
                                    {subtitle}
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </section>
        );
    }

    return (
        <>
            {isFormShowing ? (
                <MobileLayout campaign={campaign} title={campaign.labels.formTitle}>
                    <div className="mb-5" dangerouslySetInnerHTML={{__html: campaign.labels.formText}}/>
                    <ClientForm
                        fields={campaign.fields}
                        rgpd={campaign.labels.rgpd}
                        loading={isFormLoading}
                        submitLabel={campaign.labels.submitForm}
                        handleSubmit={handleSubmit}
                    />
                </MobileLayout>
            ) : (
                <div className={classes.root}>
                    <div className={classes.background} style={{
                        backgroundImage: `url(${campaign.files.backgroundMobilePortrait})`,
                        left: backgroundLeftOffset,
                        height: backgroundWidth,
                        width: backgroundWidth
                    }}/>
                    {hasWon && (
                        <Confetti
                            width={width}
                            height={height}
                            recycle={false}
                            onConfettiComplete={handleConfettiComplete}
                            run
                        />
                    )}

                    <div className={classes.gameArea} style={{height: `${gameHeight}px`, paddingTop: wheelTop}}>
                        <div className={classes.wheel} style={{width: wheelSize, height: wheelSize}}>
                            <Roulette
                                rouletteBackground={campaign.files.rouletteUnderHighlight}
                                rouletteOverlay={campaign.files.rouletteHighlight}
                                highlightImage={campaign.files.rouletteOnHighlight}
                                pointerImage={campaign.files.roulettePointer}
                                nbZones={campaign.zones}
                                targetZone={participation ? targetZone : null}
                                onComplete={handleWheelComplete}
                            />
                        </div>
                        <div style={{height: buttonAreaHeight}}>
                            {!participation && !isPlayLoading && (
                                <img onClick={handleGame} src={campaign.files.playButton} alt="Jouer"/>
                            )}
                        </div>
                        <div className={classes.footer} style={{height: footerHeight, fontSize: footerFontSize}}>
                            <span>&copy; Happy Wheel</span>
                            <a target="_blank" href={campaign.files.cgu}>{campaign.labels.cgu}</a>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default MobileView;
