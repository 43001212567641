import React, {useEffect, useContext, useMemo, useCallback, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useChangeHandler, useTranslate} from 'hooks';
import DatePicker from '../../../components/DatePicker';
import Select from 'react-select';
import {fetchUsers, clearUsersData} from 'state/actions/users';
import {CAMPAIGN_STEPS} from '../../../constants/CampaignSteps';
import CampaignFormContext from "../CampaignFormContext";
import dateToFirebaseDate from "../../../utils/dateToFirebaseDate";
import FormFooter from "../FormFooter";
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import Switch from "react-switch";

const getAddressItem = (addressComponent, needed) => addressComponent.find((item) => item.types.find((type) => needed === type))?.long_name;


const GeneralTab = () => {
    const {t} = useTranslate();
    const {campaign, setCampaign, saveCampaign} = useContext(CampaignFormContext);

    const [isLoading, setLoading] = useState(false);
    const [value, setValue] = useState(campaign?.address?.line1);
    const [noAddress, setNoAddress] = useState(false);

    const dispatch = useDispatch();
    const {loadingUser, isAdmin, usersList, loggedUser} = useSelector(state => ({
        loadingUser: state.users.loading,
        isAdmin: state.auth.userData.isAdmin,
        usersList: state.users.data,
        loggedUser: state.auth.userData
    }));

    const userOptions = usersList.map(user => ({value: user.id, label: user.name}));
    const currentOwner = userOptions.find(user => !!campaign.ownerId ? user.value === campaign.ownerId : user.value === 'null');
    const maxCampaignDate = isAdmin ? null : new Date(loggedUser.endDate);

    /**
     * If the logged-in user is an administrator, retrieve list of users, otherwise assign the current user as the campaign owner.
     */
    useEffect(() => {
        if (isAdmin) {
            dispatch(fetchUsers());
        } else {
            setCampaign(prevState => ({...prevState, ownerId: loggedUser.id}));
        }

        return () => dispatch(clearUsersData());
    }, [setCampaign, dispatch, isAdmin]);


    const handleChange = useChangeHandler(setCampaign);

    const handleChangeOwner = (event) => {
        const value = event.value;
        setCampaign(prevState => ({...prevState, ownerId: value}));
    }

    const handleDateChange = (name, date) => {
        const formattedDate= name === 'startDate' ? startOfDay(date) : endOfDay(date);
        const firebaseDate = dateToFirebaseDate(formattedDate);
        if (name === 'startDate' && firebaseDate.seconds > campaign.endDate.seconds) {
            setCampaign({
                ...campaign,
                startDate: firebaseDate,
                endDate: firebaseDate
            });
        } else {
            setCampaign({
                ...campaign,
                [name]: firebaseDate
            });
        }
    };

    const handleAddressChange = useCallback(
        (e) => {
            const { place_id: placeId } = e.value;

            setLoading(true);
            setValue(e.label);

            geocodeByPlaceId(placeId)
                .then((results) => {
                    const data = results[0];
                    const streetNumber = getAddressItem(data.address_components, 'street_number');
                    const route = getAddressItem(data.address_components, 'route');
                    const city = getAddressItem(data.address_components, 'locality');
                    const country = getAddressItem(data.address_components, 'country');
                    const zip = getAddressItem(data.address_components, 'postal_code');

                    const address = {
                        line1: [streetNumber, route].filter((v) => v).join(' ') || null,
                        city,
                        country,
                        zip,
                        placeId,
                        latitude: data.geometry.location.lat(),
                        longitude: data.geometry.location.lng(),
                    };
                    setCampaign((prevState) => ({...prevState, address}));
                })
                .finally(() => setLoading(false));
        },
        [setCampaign]
    );

    const handleAddressStatus = (checked) => {
        setCampaign((prevState) => ({...prevState, isAddressEnabled: checked}));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (campaign.isAddressEnabled && !campaign.address ) {
            setNoAddress(true);
        } else {
            setNoAddress(false);
        }
        saveCampaign(CAMPAIGN_STEPS.general, CAMPAIGN_STEPS.interface);
    };

    const addressSelectProps = useMemo(
        () => ({
            isLoading,
            placeholder: t('GeneralForm.addressPlaceholder'),
            value: value ? { label: value, value } : undefined,
            loadingMessage: () => t('GeneralForm.loading'),
            noOptionsMessage: () => t('GeneralForm.noOption'),
            onChange: handleAddressChange,
            required: true
        }),
        [isLoading, value, handleAddressChange, t]
    );

    return (
        <form onSubmit={handleSubmit}>
            <div className="columns is-multiline">
                <div className={`column ${isAdmin ? 'is-half' : 'is-full'}`}>
                    <div className="field">
                        <label className="label required">
                            {t('GeneralForm.name')}
                        </label>
                        <div className="control">
                            <input
                                id="name"
                                className="input"
                                type="text"
                                name="name"
                                value={campaign.name}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                {isAdmin && (
                    <div className="column">
                        <div className="field">
                            <label className="label required">
                                {t('GeneralForm.owner')}
                            </label>
                            <div className="control">
                                <Select
                                    id="ownerId"
                                    name="ownerId"
                                    isLoading={loadingUser}
                                    defaultValue={currentOwner}
                                    onChange={handleChangeOwner}
                                    options={userOptions}
                                    placeholder={t('GeneralForm.ownerPlaceholder')}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="column is-half">
                    <div className="field">
                        <label className="label required">
                            {t('GeneralForm.startDate')}
                        </label>
                        <div className="control">
                            <DatePicker
                                name="startDate"
                                onChange={(date) => handleDateChange('startDate', date)}
                                date={campaign.startDate}
                                minDate={new Date()}
                                disabled={campaign.isStarted && !isAdmin}
                            />
                        </div>
                    </div>
                </div>
                <div className="column is-half">
                    <div className="field">
                        <label className="label required">
                            {t('GeneralForm.endDate')}
                        </label>
                        <div className="control">
                            <DatePicker
                                name="endDate"
                                date={campaign.endDate}
                                minDate={campaign.startDate}
                                maxDate={maxCampaignDate}
                                onChange={(date) => handleDateChange('endDate', date)}
                                disabled={campaign.isStarted && !isAdmin}
                            />
                        </div>
                    </div>
                </div>
                <div className="column is-half">
                    <div className="field">
                        <label className="label required">
                            {t('GeneralForm.maxParticipationsPerUser')}
                        </label>
                        <div className="control">
                            <input
                                id="maxParticipationsPerUser"
                                className="input"
                                type="number"
                                required
                                name="maxParticipationsPerUser"
                                min={0}
                                value={campaign.maxParticipationsPerUser}
                                onChange={handleChange}
                                disabled={campaign.isStarted && !isAdmin}
                            />
                            <p className="help">{t('GeneralForm.helpMaxParticipationsPerUser')}</p>
                        </div>
                    </div>
                </div>
                <div className="column is-half">
                    <div className="field">
                        <label className="label required">
                            {t('GeneralForm.intervalParticipationPerUser')}
                        </label>
                        <div className="control">
                            <input
                                id="intervalParticipationPerUser"
                                className="input"
                                type="number"
                                required
                                name="intervalParticipationPerUser"
                                min={0}
                                value={campaign.intervalParticipationPerUser}
                                onChange={handleChange}
                                disabled={campaign.isStarted && !isAdmin}
                            />
                            <p className="help">{t('GeneralForm.helpIntervalParticipationPerUser')}</p>
                        </div>
                    </div>
                </div>

                <label className="is-flex is-aligned-center mt-4 mb-4 column is-full">
                    <Switch onChange={handleAddressStatus} checked={campaign.isAddressEnabled}/>
                    <div className="ml-3">
                        {t('GeneralForm.addressRequest')}
                    </div>
                </label>

                {campaign.isAddressEnabled && (
                    <>
                        <div className="column is-half">
                            <div className="field">
                                <label className="label required">
                                    {t('GeneralForm.address')}
                                </label>
                                <div className="control">
                                    <GooglePlacesAutocomplete
                                        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                        apiOptions={{ language: 'fr', region: 'fr' }}
                                        autocompletionRequest={{
                                            componentRestrictions: {
                                                country: ['fr'],
                                            },
                                            fields: ['street_number', 'route'],
                                        }}
                                        selectProps={addressSelectProps}
                                    />
                                    {noAddress && <p className="help is-danger">{t('GeneralForm.requiredAddress')}</p>}
                                </div>
                            </div>
                        </div>

                        <div className="column is-half">
                            <div className="field">
                                <label className="label required">
                                    {t('GeneralForm.distance')}
                                </label>
                                <div className="control">
                                    <input
                                        id="participationDistance"
                                        className="input"
                                        type="number"
                                        required
                                        name="participationDistance"
                                        min={100}
                                        value={campaign.participationDistance}
                                        onChange={handleChange}
                                        disabled={campaign.isStarted && !isAdmin}
                                    />
                                    <p className="help">{t('GeneralForm.helpDistance')}</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <FormFooter/>
        </form>
    );
};

export default GeneralTab;
