import React, {useEffect, useState, memo} from 'react';
import {EditorState, ContentState} from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import {Editor} from "react-draft-wysiwyg";
import PropTypes from 'prop-types';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function WysiwygEditor({defaultValue, onEditorStateChange}){
    const [value, setValue] = useState(EditorState.createEmpty());

    useEffect(() => {
        const { contentBlocks, entityMap } = htmlToDraft(defaultValue);
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setValue(EditorState.createWithContent(contentState));
    }, []);

    const handleChange = (editorState) => {
        setValue(editorState);

        if ('function' === typeof onEditorStateChange) {
            onEditorStateChange(editorState);
        }
    }

    return (
        <Editor
            editorState={value}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={handleChange}
            toolbar={{
                options: ['inline', 'fontSize', 'textAlign', 'list', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough'],
                },
                list: {
                    options: ['unordered', 'ordered'],
                }
            }}
        />
    );
}

WysiwygEditor.propTypes = {
    defaultValue: PropTypes.string.isRequired,
    onEditorStateChange: PropTypes.func.isRequired,
}

WysiwygEditor.defaultProps = {
    defaultValue: '',
    onEditorStateChange: null,
}

export default memo(WysiwygEditor);