import {useEffect, useState} from 'react';

const useAsyncEffect = (fn, deps = []) => {
    const [, setState] = useState(); // This is a fake state used to throw an error catchable by the errorBoundary
    /* eslint-disable */
    useEffect(() => {
        Promise.resolve(fn()).catch((e) =>
            setState(() => {
                throw e; // The magic happens here ;)
            })
        );
    }, deps);
}

export default useAsyncEffect;