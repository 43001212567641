import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import useAsyncEffect from "./useAsyncEffect";
import firebase from "../firebase";
import useDeviceToken from "./useDeviceToken";
import paths from "../pages/Router/paths";

const usePublicCampaign = (campaignId, withCache = true) => {
    const deviceToken = useDeviceToken();
    const history = useHistory();
    const [campaign, setCampaign] = useState(null);

    useAsyncEffect(async () => {
        const cache = sessionStorage.getItem(`campaign_${campaignId}`);
        if (cache && withCache){
            setCampaign(JSON.parse(cache));
            return;
        }

        if (campaignId && deviceToken) {
            try {
                const getCampaign = firebase.functions().httpsCallable('httpsGetPublicCampaign');
                const res = await getCampaign({campaignId, deviceToken});

                sessionStorage.setItem(`campaign_${campaignId}`, JSON.stringify(res.data));
                setCampaign(res.data);
            } catch (err) {
                const {message} = err;
                history.push(`${paths.PUBLIC_ERROR}?error=${encodeURIComponent(message)}`);
            }
        }
    }, [campaignId, deviceToken]);

    return campaign;
};

export default usePublicCampaign;