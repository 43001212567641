const firebaseDateToDate = (date) => {
    if (date instanceof Date){
        return date;
    }

    if (!date?.seconds){
        return null;
    }

    return new Date(date?.seconds * 1000);
};

export default firebaseDateToDate;