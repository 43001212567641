const LABELS = [
    {key: 'color', type: 'color', required: true},
    {key: 'companyName', type: 'string', required: true},
    {key: 'titleFont', type: 'string', required: false},
    {key: 'goodLuckTitle', type: 'string', required: false},
    {key: 'waitingTitle', type: 'string', required: false},
    {key: 'rgpd', type: 'wysiwyg', required: true},
    {key: 'cgu', type: 'string', required: true},
    {key: 'formMetaTitle', type: 'string', required: true},
    {key: 'formTitle', type: 'string', required: true},
    {key: 'formText', type: 'wysiwyg', required: false},
    {key: 'submitForm', type: 'string', required: true},
    {key: 'playMetaTitle', type: 'string', required: true},
    {key: 'prizesMetaTitle', type: 'string', required: true},
    {key: 'prizesListTitle', type: 'string', required: true},
    {key: 'noPrize', type: 'wysiwyg', required: true},
    {key: 'errorMetaTitle', type: 'string', required: true},
    {key: 'errorTitle', type: 'string', required: true},
    {key: 'errorDefault', type: 'wysiwyg', required: true},
    {key: 'campaign/max-games-reached', type: 'wysiwyg', required: true},
    {key: 'campaign/play-interval', type: 'wysiwyg', required: true},
    {key: 'companyButton', type: 'string', required: false},
    {key: 'prizeMessage', type: 'wysiwyg', required: false},
    {key: 'companyUrl', type: 'string', required: false},
    {key: 'facebookMessage', type: 'string', required: false},
    {key: 'facebookShareUrl', type: 'string', required: false},
    {key: 'twitterMessage', type: 'string', required: false},
    {key: 'twitterShareUrl', type: 'string', required: false},
    {key: 'lostRedirect', type: 'string', required: false},
];

export default LABELS;
