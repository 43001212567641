export const CAMPAIGN_STEPS = {general: 'general', interface: 'interface', fields: 'fields', prizes: 'prizes'};
const stepsOrder = [CAMPAIGN_STEPS.general, CAMPAIGN_STEPS.interface, CAMPAIGN_STEPS.fields, CAMPAIGN_STEPS.prizes];

export const isEnableStep = (neededStep, currentStep) => {
  const neededStepIndex = stepsOrder.findIndex((step) => neededStep === step);
  const currentStepIndex = stepsOrder.findIndex((step) => currentStep === step);

  return neededStepIndex <= currentStepIndex ;
};

export const availableSteps = (currentStep) => {
  return stepsOrder.filter((step) => isEnableStep(step, currentStep));
};

export const isGreatestStep = (currentStep, compareStep) => {
  const currentStepIndex = stepsOrder.findIndex((step) => currentStep === step);
  const compareStepIndex = stepsOrder.findIndex((step) => compareStep === step);

  return compareStepIndex > currentStepIndex;
};